<template>
  <section id="login" class="p_3">
    <div class="container-fluid">
      <div class="row login_1">
        <!-- Left Image Section -->
        <div class="col-md-6 pt-5 d-none d-md-block">
          <img
            src="/assets/img/10022.svg"
            alt="Login Image"
            class="img-fluid d-block mx-auto p-5"
          />
        </div>

        <!-- Right Register Section -->
        <div class="col-md-6">
          <div class="login_1l text-center pt-3">
            <div class="login_1l1">
              <img src="/assets/img/logo.svg" alt="Logo" class="mb-3" width="280" />
               
               <h2 class="bg-light p-3 rounded-3 fs-10 mb-3 pt-3">Login</h2>


              <div v-if="mobileError" class="text-danger mb-2">{{ mobileError }}</div> <!-- Error message for mobile -->
              <div v-if="passwordError" class="text-danger mb-2">{{ passwordError }}</div> <!-- Error message for password -->
                           
              <!-- Username input -->
              <input class="form-control rounded-3 mb-3" id="mobile" v-model="mobile" placeholder="Enter Number" type="text" />
              

              <!-- Password input -->
              <input class="form-control rounded-3" id="password" v-model="password" placeholder="Password" type="password" />
              

              <div class="login_1li row mt-3">
                <div class="col-6 text-start"></div>
                <div class="col-6 text-end">
                  <h6 class="mb-0">
                    <a class="col_yell" href="#">Forgot Password?</a>
                  </h6>
                </div>
              </div>
            </div>

            <!-- Loader (visible when loading is true) -->
            <div v-if="loading" id="loader-small" class="loader pb-2">
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
            </div>

            <!-- Login button -->
            <h6 class="mb-0 mt-2 center_sm">
              <button class="button" @click.prevent="login" style="border: none;">Login</button>
            </h6>

            <h6 class="mt-3">
              don't have an account? 
              <router-link class="col_yell" aria-current="page" :to="{ name: 'register-view' }">
                Sign Up
              </router-link>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Validator } from "@/other/Validator";
import axios from "axios";


export default {
  name: "LoginView",

  data() {
    return {
      mobile: '',
      password: '',
      loading: false,
      mobileError: '', // Error message for mobile
      passwordError: '' // Error message for password
    };
  },

  methods: {
  login() {
    this.mobileError = ''; // Reset mobile error
    this.passwordError = ''; // Reset password error

    var validator = new Validator();
    var error = validator.validated([
      { field: "mobile", value: this.mobile, type: "required" },
      {
        field: "password",
        value: this.password,
      },
    ]);

    if (error) {
      console.log(error);
    } else {
      this.loading = true;

      axios
        .post("home/login", {
          mobile: this.mobile,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("user", JSON.stringify(response.data)); // Store token
          this.loading = false;

          const userRole = response.data.user_role;
          if (userRole === "admin") {
            this.$router.push({ name: "admin-dashboard" });
          } else if (userRole === "customer") {
            this.$router.push({ name: "user-dashboard-view" });
          }
        })
        .catch((error) => {
          this.loading = false;

          // Reset error messages
          this.mobileError = '';
          this.passwordError = '';

          // Check for specific error messages
          if (error.response && error.response.data.message) {
            if (error.response.data.errors) {
              if (error.response.data.errors.mobile) {
                this.mobileError = error.response.data.errors.mobile[0];
              }
              if (error.response.data.errors.password) {
                this.passwordError = error.response.data.errors.password[0];
              }
            } else {
              this.mobileError = error.response.data.message; // For generic errors
            }
          } else {
            console.error("An unexpected error occurred:", error);
          }
        });
    }
  },
},


  created() {
    var scripts = ["/assets/js/app.js"];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      var allscript = document.getElementById("allscript");
      allscript.appendChild(tag);
    });
  }
};
</script>

<style scoped>
/* Style adjustments if necessary */
#login .login_1l img {
  max-width: 100%; /* Ensures the image is responsive */
  height: auto;
}

.login_1l1 {
  margin: 60px;
}

/* Loader styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading__dot_small {
  height: 10px;
  width: 10px;
  background-color: #568187;
  border-radius: 50%;
  margin: 0 2px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
</style>
