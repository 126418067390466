<template>
  

  <section id="center" class="center_o bg_blue11 p_3">
    <div class="container-xl">
      <div class="row center_o1">
        <div class="col-md-12">
          <h2 class="text-white">Contact</h2>
          <h6 class="mb-0 mt-3 text-white">
            <a class="text-white" href="#">Home</a>
            <span class="mx-2 text-white">/</span> Contact
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" class="p_3">
    <div class="container-xl">
      <div class="row contact_1">
        <div class="col-md-6">
          <div class="contact_1l p-4 pt-5">
            <!-- <h3>Contact Us</h3> -->
            <p>
              Reach out to us through this form. We are here to help with your
              matrimony needs in Bangladesh.
            </p>

            <div class="row mt-5">
            <div class="col-md-1 mt-3">
                <i class="fa fa-home me-1 col_yell"></i>
            </div>

            <div class="col-md-11">
               Islam Tower, Level 6, 464/H DIT Road, <br> Rampura, Dhaka, Bangladesh
            </div>
                   
            </div>

            

            <div class="row mt-4">
            <div class="col-md-1 mt-3">
                <i class="fa fa-phone me-1 col_yell"></i>
            </div>

            <div class="col-md-11">
               +88 02-55128812 <br> +880 1742 111222
            </div>
                   
            </div>

            <div class="row mt-4">
            <div class="col-md-1">
                <i class="fa fa-envelope me-1 col_yell"></i>
            </div>

            <div class="col-md-11">
               info@gmail.com
            </div>
                   
            </div>
          
          
          </div>
        </div>
        <div class="col-md-6">
          <div class="contact_1r p-4 rounded-3 bg-light">
            <input
              class="form-control bg-white rounded-3 border-0"
              placeholder="Full Name"
              type="text"
            />
            <input
              class="form-control bg-white rounded-3 border-0 mt-3"
              placeholder="Email Address"
              type="text"
            />
            <input
              class="form-control bg-white rounded-3 border-0 mt-3"
              placeholder="Phone"
              type="text"
            />
            
            
            <textarea
              placeholder="Message"
              class="form-control bg-white rounded-3 border-0 mt-3 form_text"
            ></textarea>
            <h6 class="mt-4 mb-0">
              <a class="button text-center d-block" href="#">Send Message</a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
