<template>
  <section id="center" class="center_h pt-5 pb-5">
    <div class="container-xl">
      <div class="row center_h1">
        <div class="col-md-4">
          <div class="p-2">
            <div class="center_h1l1 p-4 center1">
              <h4 class="text-white">Find Your Life Partner</h4>
              <p class="mt-3 text-white">
                We are here to help you find a spouse who shares your Islamic
                values.
              </p>
              <select
                class="form-select fw-bold"
                v-model="filters.gender"
                aria-label="I'm looking for a"
              >
                <option value="">I'm looking for a</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <div v-if="errors.gender" class="text-white">{{ errors.gender }}</div>

              <div class="center_h1l1i row">
                <div class="col-md-6">
                  <div class="center_h1l1il">
                    <input
                      type="number"
                      class="form-control fw-bold mt-3"
                      placeholder="Age Min"
                      v-model="filters.min_age"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="center_h1l1il">
                    <input
                      type="number"
                      class="form-control fw-bold mt-3"
                      placeholder="Age Max"
                      v-model="filters.max_age"
                    />
                  </div>
                </div>
                
                <div class="col-md-12">
                  <div class="center_h1l1il">
                    <input
                      type="text"
                      class="form-control fw-bold mt-3"
                      placeholder="District"
                      v-model="filters.district"
                    />
                  </div>
                </div>
              </div>

              <h6 class="mb-0 mt-4">
                <a
                  class="button_1 d-block text-center"
                  href="#"
                  @click.prevent="search"
                  >Search Partner</a
                >
              </h6>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="center_h1r"></div>
        </div>
      </div>
    </div>
  </section>

  <section id="serv_h" class="p_3 pt-5">
    <div class="container-xl">
      <div class="row serv_h1 mb-4">
        <div class="col-md-9">
          <div class="serv_h1l">
            <h3>How We Works</h3>
            <p class="mb-0">Our Approach to Creating Magical Wedding Moments</p>
          </div>
        </div>
        <!-- <div class="col-md-3">
            <div class="serv_h1r text-end pt-2">
              <h6 class="mb-0"><a class="button" href="#">All Services</a></h6>
            </div>
          </div> -->
      </div>
      <div class="row serv_h2">
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/Personalized.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-end position-absolute w-100 px-3">
                <!-- <span>
                  <a
                    class="d-inline-block bg_yell text-white text-center rounded-circle fs-3"
                    href="#"
                  >
                    <i class="fa fa-handshake"></i>
                    Replace with handshake icon
                  </a>
                </span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-5">
              <h5>Personalized Matchmaking</h5>
              <p class="mb-0">
                We offer personalized matchmaking guided by Islamic Shariah
                principles and powered by AI technology.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/Background.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-end position-absolute w-100 px-3">
                <!-- <span>
                  <a
                    class="d-inline-block bg_yell text-white text-center rounded-circle fs-3"
                    href="#"
                  >
                    <i class="fa fa-check-circle"></i>
                    
                  </a>
                </span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-5">
              <h5>Background Verification</h5>
              <p class="mb-0">
                Thorough background checks are conducted via physical
                verifications and phone interviews.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/Confidential.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-end position-absolute w-100 px-3">
                <!-- <span>
                  <a
                    class="d-inline-block bg_yell text-white text-center rounded-circle fs-3"
                    href="#"
                  >
                    <i class="fa fa-eye-slash"></i>
                
                  </a>
                </span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-5">
              <h5>Confidential Meetings</h5>
              <p class="mb-0">
                We arrange private meetings between partners and guardians,
                ensuring confidentiality throughout the process.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/wedding.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-end position-absolute w-100 px-3">
                <!-- <span>
                  <a
                    class="d-inline-block bg_yell text-white text-center rounded-circle fs-3"
                    href="#"
                  >
                    <i class="fa fa-user-friends"></i>
                    
                  </a>
                </span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-5">
              <h5>Wedding Support</h5>
              <p class="mb-0">
                We offer comprehensive support for pre-wedding, wedding, and
                post-wedding events. Visit our Service section.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row serv_h4 mt-4 text-center">
        <!-- <div class="col-md-12">
            <h6 class="mb-0"><a class="button" href="#">All Services</a></h6>
          </div> -->
      </div>
      <!-- <div class="row serv_h3 mt-4 bg_blue pt-5 pb-5 mx-0 px-4">
        <div class="col-md-8">
          <div class="serv_h3l">
            <h1 class="text-white">Need Help Planning Your Wedding?</h1>
            <p class="text-white-50 mt-3">
              Planning your dream wedding can be overwhelming, but we’re here to
              make it effortless. Our experienced team provides personalized,
              step-by-step wedding planning assistance, ensuring every detail is
              perfect. Let us handle the stress while you enjoy your special
              day.
            </p>
            <h6 class="mt-4 mb-0">
              <a class="button_2" href="#">Contact</a>
            </h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="serv_h3r">
            <div class="grid clearfix">
              <figure class="effect-jazz mb-0">
                <a href="#"
                  ><img src="/assets/img/need.jpg" class="w-100" alt="abc"
                /></a>
              </figure>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>

  <!-- <section id="occasion" class="p_3 pt-4">
    <div class="container-xl">
      <div class="occasion_1 row">
        <div class="col-md-6 pe-0">
          <div class="occasion_1l">
            <div class="grid clearfix">
              <figure class="effect-jazz mb-0">
                <a href="#"
                  ><img src="/assets/img/1001.jpg" class="w-100" alt="abc"
                /></a>
              </figure>
            </div>
          </div>
        </div>
        <div class="col-md-6 ps-0">
          <div class="occasion_1r bg_light1 text-center pt-4 pe-4 pb-4">
            <div class="occasion_1ri p-4 bg-white">
              <span class="text-danger fs-1"><i class="fa fa-users"></i></span>
              <h1>Finding Your Perfect Match</h1>
              <h6 class="text-muted">
                Dedicated to Building Strong Partnerships
              </h6>
              <h1>Join Our Community Today</h1>

              <p class="mt-3 font_14">
                We specialize in connecting singles in Bangladesh with their
                perfect match, offering a tailored and confidential service. Our
                extensive network and dedicated team ensure that we meet your
                individual needs, providing a reliable and supportive platform
                for finding love. Experience a personalized journey to lifelong
                companionship with our trusted matrimony services.
              </p>
              <h5 class="mt-3 fs-6">Start Your Journey with Us</h5>
              <hr class="line_2 mx-auto mb-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section id="profile" class="p_3 bg-light">
    <div class="container-xl">
      <div class="profile_1 row text-center mb-4">
        <div class="col-md-12">
          <h2>Recent Profiles</h2>
          <p class="mb-0">
            Start planning your wedding with us, with Low cost!
          </p>
        </div>
      </div>

      <div id="demo"></div>

      <!-- <carousel
        :responsive="{
          0: { items: 1, nav: false, dots: true },
          600: { items: 2, nav: false, dots: true },
          1000: { items: 3, nav: false, dots: true },
          1200: { items: 4, nav: false, dots: true },
        }"
        autoplay
        :autoplay-timeout="3000"
        :loop="true"
        :autoplay-hover-pause="true"
        dots
      >
        <div
         v-for="(profile, index) in profiles"
          :key="index" 
          class="forum_1i position-relative profile-card"
        >
          <div class="forum_1i2 p-2 border_1">
            <div class="d-flex align-items-center">
              <div class="avatar mr-3">
                <img
                  src="/assets/img/avatar.png"
                  alt="Avatar"
                  class="img-fluid rounded-circle"
                />
              </div>

              <div class="profile-info">
                <h6 class="mt-2 mb-2">
                  <a href="#" class="fw-bold">{{ profile.name || "N/A" }}</a>
                </h6>
                <p>Age: {{ profile.age || "N/A" }}</p>
                <p>Height: {{ profile.height || "N/A" }}</p>
                <p>{{ profile.profession || "N/A" }}</p>
                <p>{{ profile.lives_in || "N/A" }}</p>
              </div>
            </div>
          </div>
        </div>
      </carousel> -->

      <!-- <carousel
        :responsive="{
          0: { items: 1, nav: false, dots: true },
          600: { items: 2, nav: false, dots: true },
          1000: { items: 3, nav: false, dots: true },
          1200: { items: 4, nav: false, dots: true },
        }"
        autoplay
        :autoplay-timeout="3000"
        :loop="true"
        :autoplay-hover-pause="true"
        dots
      >
        <div
          v-for="(profile, index) in profiles"
          :key="index"
          class="forum_1i position-relative profile-card"
        >
          <div class="forum_1i2 p-2 border_1">
            <div class="d-flex align-items-center">
              <div class="avatar mr-3">
                <img
                  src="/assets/img/avatar.png"
                  alt="Avatar"
                  class="img-fluid rounded-circle"
                />
              </div>

              <div class="profile-info">
                <h6 class="mt-2 mb-2">
                  <a href="#" class="fw-bold">{{ profile.name }}</a>
                </h6>
                <p>Age: {{ profile.age }}</p>
                <p>Height: {{ profile.height }}</p>
                <p>{{ profile.profession }}</p>
                <p>{{ profile.location }}</p>
              </div>
            </div>
          </div>
        </div>
      </carousel> -->

      <!-- <div class="owl-carousel owl-theme">
        <div class="forum_1i position-relative">
          <div class="forum_1i2 p-4 border_1">
            <h6 class="mt-3">
              <a href="#">Name: Lorem Ipsum</a>
            </h6>

            <div class="row mt-3">
              <div class="col-md-6">
                <p>Age: 30</p>
                <p>Software Engineer</p>
              </div>
              <div class="col-md-6">
                <p>5.5"</p>
                <p>Dhaka</p>
              </div>
            </div>
          </div>
        </div>
        <div class="forum_1i position-relative">
          <div class="forum_1i2 p-4 border_1">
            <h6 class="mt-3">
              <a href="#">Name: Lorem Ipsum</a>
            </h6>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>Age: 30</p>
                <p>Software Engineer</p>
              </div>
              <div class="col-md-6">
                <p>5.5"</p>
                <p>Dhaka</p>
              </div>
            </div>
          </div>
        </div>
        <div class="forum_1i position-relative">
          <div class="forum_1i2 p-4 border_1">
            <h6 class="mt-3">
              <a href="#">Name: Lorem Ipsum</a>
            </h6>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>Age: 30</p>
                <p>Software Engineer</p>
              </div>
              <div class="col-md-6">
                <p>5.5"</p>
                <p>Dhaka</p>
              </div>
            </div>
          </div>
        </div>
        <div class="forum_1i position-relative">
          <div class="forum_1i2 p-4 border_1">
            <h6 class="mt-3">
              <a href="#">Name: Lorem Ipsum</a>
            </h6>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>Age: 30</p>
                <p>Software Engineer</p>
              </div>
              <div class="col-md-6">
                <p>5.5"</p>
                <p>Dhaka</p>
              </div>
            </div>
          </div>
        </div>

        <div class="forum_1i position-relative">
          <div class="forum_1i2 p-4 border_1">
            <h6 class="mt-3">
              <a href="#">Name: Lorem Ipsum</a>
            </h6>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>Age: 30</p>
                <p>Software Engineer</p>
              </div>
              <div class="col-md-6">
                <p>5.5"</p>
                <p>Dhaka</p>
              </div>
            </div>
          </div>
        </div>

        <div class="forum_1i position-relative">
          <div class="forum_1i2 p-4 border_1">
            <h6 class="mt-3">
              <a href="#">Name: Lorem Ipsum</a>
            </h6>
            <div class="row mt-3">
              <div class="col-md-6">
                <p>Age: 30</p>
                <p>Software Engineer</p>
              </div>
              <div class="col-md-6">
                <p>5.5"</p>
                <p>Dhaka</p>
              </div>
            </div>
          </div>
        </div>
       
      </div> -->

      <div class="row serv_h4 mt-5 text-center">
        <div class="col-md-12">
          <h6 class="mb-0"><a class="button_1" href="#">More Profile</a></h6>
        </div>
      </div>
    </div>
  </section>

  <section id="vend" class="p_3">
    <div class="container-xl">
      <div class="row serv_h1 mb-4">
        <div class="col-md-9">
          <div class="serv_h1l">
            <h3>Our Service</h3>
            <p class="mb-0">
              Finds experienced wedding professionals for all categories!
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="serv_h1r text-end pt-2">
            <h6 class="mb-0">
              <!-- <a class="button_1" href="#">More Vendors</a> -->
            </h6>
          </div>
        </div>
      </div>
      <div class="row serv_h2">
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/Pre Wedding.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-center position-absolute w-100 px-3">
                <!-- <span
                  ><a
                    class="d-inline-block bg_blue text-white text-center rounded-circle fs-3"
                    href="#"
                    ><i class="fa fa-camera"></i></a
                ></span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-4 text-center">
              <h5 class="mt-5">Pre Wedding</h5>
              <p class="mb-0">Photography, event planning services</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/Venue Booking.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-center position-absolute w-100 px-3">
                <!-- <span
                  ><a
                    class="d-inline-block bg_blue text-white text-center rounded-circle fs-3"
                    href="#"
                    ><i class="fa fa-building"></i></a
                ></span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-4 text-center">
              <h5 class="mt-5">Venue Booking</h5>
              <p class="mb-0">Find and book venues for any event.</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/Genie Services.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-center position-absolute w-100 px-3">
                <!-- <span
                  ><a
                    class="d-inline-block bg_blue text-white text-center rounded-circle fs-3"
                    href="#"
                    ><i class="fa fa-magic"></i></a
                ></span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-4 text-center">
              <h5 class="mt-5">Genie Services</h5>
              <p class="mb-0">On-demand services for any event-related</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="serv_h2i border_1 p-2">
            <div class="serv_h2i1 position-relative">
              <div class="serv_h2i1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/Grooming.jpg"
                        class="w-100"
                        alt="abc"
                    /></a>
                  </figure>
                </div>
              </div>
              <div class="serv_h2i1i1 text-center position-absolute w-100 px-3">
                <!-- <span
                  ><a
                    class="d-inline-block bg_blue text-white text-center rounded-circle fs-3"
                    href="#"
                    ><i class="fa fa-cut"></i></a
                ></span> -->
              </div>
            </div>
            <div class="serv_h2i2 mt-4 text-center">
              <h5 class="mt-5">Grooming</h5>
              <p class="mb-0">Personal care for appearance and hygiene</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row serv_h4 mt-5 text-center">
        <div class="col-md-12">
          <h6 class="mb-0"><a class="button_1" href="#">More Services</a></h6>
        </div>
      </div>
    </div>
  </section>

<section id="explore">
  <div class="explorem bg_back">
    <img src="/assets/img/123.jpg" alt="Background Image" class="img-fluid w-100">
    <div class="container-xl">
      <div class="row explore_1 text-center">
        <!-- Your content here -->
      </div>
    </div>
  </div>
</section>



  <section id="story" class="p_3">
    <div class="container-xl">
      <div class="profile_1 row text-center mb-4">
        <div class="col-md-12">
          <h2>Real Stories</h2>
          <p class="mb-0">
            Explore the beautiful journeys of couples united in faith.
          </p>
        </div>
      </div>
      <div class="story_1 row">
        <div class="col-md-6">
          <div class="story_1l">
            <div class="story_1l1 position-relative">
              <div class="story_1l1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/550.jpg"
                        class="w-100"
                        alt="Couple in Love"
                    /></a>
                  </figure>
                </div>
              </div>
              <!-- <div class="story_1l1i1 position-absolute text-end w-100 px-3">
              <img
                src="/assets/img/8.jpg"
                class="rounded-circle"
                width="60"
                height="60"
                alt="Profile Picture"
              />
            </div> -->
            </div>
            <div class="story_1l mt-4">
              <h3 class="mb-3">Aisha & Bilal</h3>
              <p>
                <i class="fa fa-quote-right fs-1 me-3"></i> "Our journey began
                with mutual respect and shared values. The support from our
                families made our bond even stronger."
              </p>
              <h6 class="mb-0 col_yell fw-bold">Dhaka, Bangladesh</h6>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="story_1l">
            <div class="story_1l1 position-relative">
              <div class="story_1l1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/300.jpg"
                        class="w-100"
                        alt="Couple Celebrating"
                    /></a>
                  </figure>
                </div>
              </div>
              <!-- <div class="story_1l1i1 position-absolute text-end w-100 px-3">
              <img
                src="/assets/img/9.jpg"
                class="rounded-circle"
                width="60"
                height="60"
                alt="Profile Picture"
              />
            </div> -->
            </div>
            <div class="story_1l mt-4">
              <h3 class="mb-3">Fatima & Omar</h3>
              <p>
                <i class="fa fa-quote-right fs-1 me-3"></i> "We were united not
                just by love but by our faith. Each moment spent together has
                strengthened our bond."
              </p>
              <h6 class="mb-0 col_yell fw-bold">Chitagong, Bangladesh</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="blog" class="p_3 bg-light">
    <div class="container-xl">
      <div class="profile_1 row mb-4">
        <div class="col-md-12">
          <h2>Inspirations, Tips, and Blogs on Islamic Matrimony</h2>
          <p class="mb-0">
            Explore insightful articles and beautiful stories from our
            community.
          </p>
        </div>
      </div>
      <div class="story_1 row">
        <div class="col-md-4">
          <div class="story_1l bg-white p-2">
            <div class="story_1l1 position-relative">
              <div class="story_1l1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/blog.jpg"
                        class="w-100"
                        alt="Islamic Wedding"
                    /></a>
                  </figure>
                </div>
              </div>
              <!-- <div class="story_1l1i1 position-absolute text-end w-100 px-3">
                            <img src="/assets/img/8.jpg" class="rounded-circle" width="60" height="60" alt="Author" />
                        </div> -->
            </div>
            <div class="story_1l mt-4 px-3 pb-3">
              <h6 class="fw-bold col_yell">ISLAMIC WEDDING TRADITIONS</h6>
              <h5>
                <a href="#"
                  >5 Beautiful Islamic Wedding Traditions You Should Know</a
                >
              </h5>
              <h6 class="mb-0">24 February 2023</h6>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="story_1l bg-white p-2">
            <div class="story_1l1 position-relative">
              <div class="story_1l1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/blog1.jpg"
                        class="w-100"
                        alt="Islamic Rings"
                    /></a>
                  </figure>
                </div>
              </div>
              <!-- <div class="story_1l1i1 position-absolute text-end w-100 px-3">
                            <img src="/assets/img/9.jpg" class="rounded-circle" width="60" height="60" alt="Author" />
                        </div> -->
            </div>
            <div class="story_1l mt-4 px-3 pb-3">
              <h6 class="fw-bold col_yell">ISLAMIC RINGS & JEWELRY</h6>
              <h5>
                <a href="#"
                  >Choosing the Perfect Islamic Wedding Ring: A Guide</a
                >
              </h5>
              <h6 class="mb-0">25 February 2023</h6>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="story_1l bg-white p-2">
            <div class="story_1l1 position-relative">
              <div class="story_1l1i">
                <div class="grid clearfix">
                  <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img
                        src="/assets/img/sunny.jpg"
                        class="w-100"
                        alt="Honeymoon"
                    /></a>
                  </figure>
                </div>
              </div>
              <!-- <div class="story_1l1i1 position-absolute text-end w-100 px-3">
                            <img src="/assets/img/11.jpg" class="rounded-circle" width="60" height="60" alt="Author" />
                        </div> -->
            </div>
            <div class="story_1l mt-4 px-3 pb-3">
              <h6 class="fw-bold col_yell">ISLAMIC HONEYMOON ADVICE</h6>
              <h5>
                <a href="#"
                  >Planning a Meaningful Honeymoon in Accordance with Islamic
                  Values</a
                >
              </h5>
              <h6 class="mb-0">28 February 2023</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="blog_1 row mt-4">
        <!-- <div class="col-md-12">
                <ul class="mb-0">
                    <li class="d-inline-block"><a href="#"> Wedding Traditions</a></li>
                    <li class="d-inline-block"><a href="#"> Rings & Jewelry </a></li>
                    <li class="d-inline-block"><a href="#"> Honeymoon Advice </a></li>
                    <li class="d-inline-block"><a href="#"> Wedding Planning </a></li>
                    <li class="d-inline-block"><a href="#"> Islamic Etiquettes</a></li>
                    <li class="d-inline-block">
                        <a class="bg_yell text-white" href="#"> View all articles</a>
                    </li>
                </ul>
            </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import carousel from "vue-owl-carousel/src/Carousel";
import $ from "jquery";

import "owl.carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
export default {
  components: {
    // carousel,
  },
  data() {
    return {

     
      filters: {
        gender: '',
        ageMin: '',
        ageMax: '',
        district: ''
      },

      errors: {
        gender: ''
      },
      // filters: {
      //   gender: "",
      //   ageMin: "",
      //   ageMax: "",
      //   district: "",
      // },

      profiles: [],
    };
  },

  watch: {
    'filters.gender'(newValue) {
      if (newValue) this.errors.gender = '';
    },
},

methods: {
    getProfiles() {
  axios
    .get("home/profiles")
    .then((response) => {
      if (response.data.success) {
        // Update profiles with data from API
        this.profiles = response.data.data;

        $("#demo").html(
          '<div id="testing" class="owl-carousel owl-theme"></div>'
        );
        for (let i = 0; i < this.profiles.length; i++) {
          const profile = this.profiles[i];
          const profileImage =
            profile.gender === "male"
              ? "/assets/img/male.png"
              : "/assets/img/female.png";

          $(".owl-carousel").append(
            `<div class="forum_1i position-relative profile-card" style="padding: 10px;">
              <div class="forum_1i2 p-1 border_1" style="padding: 10px;">
                <div class="d-flex align-items-center">
                  <div class="avatar mr-3">
                    <img style="width: 80px; height: 80px;" src="${profileImage}" alt="Avatar" class="rounded-circle" />
                  </div>
                  <div class="profile-info" style="font-size: 0.9rem;">
                    <h6 class="mt-1 mb-2">
                      <a href="#" class="fw-bold">${profile.name || "N/A"}</a>
                    </h6>
                    <p style="margin: 2px 0;">Age: ${profile.age || "N/A"}</p>
                    <p style="margin: 2px 0;">Height: ${profile.height || "N/A"} cm</p>
                    <p style="margin: 2px 0;">${profile.designation || "N/A"}</p>
                    <p style="margin: 2px 0;">${profile.district || "N/A"}</p>
                  </div>
                </div>
              </div>
            </div>`
          );
        }

        $("#testing").owlCarousel({
          loop: true,
          margin: 10,
          navigation: true,
          nav: true,
          autoplay: true,
          autoplayTimeout: 3000,
          autoplayHoverPause: true,
          responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1000: { items: 4 },
          },
        });
      } else {
        console.error("Failed to fetch profiles:", response.data.message);
      }
    })
    .catch((error) => {
      console.error("Error fetching profiles:", error);
    });
},


    owl() {
      $(".owl-carousel").owlCarousel();
    },


     search() {
      // Clear previous error messages
      this.errors = { gender: ''};
      
      let isValid = true;

      // Validate gender
      if (!this.filters.gender) {
        this.errors.gender = "Please select gender";
        isValid = false;
      }

      // Validate age range
      // if (this.filters.min_age && this.filters.max_age && this.filters.min_age > this.filters.max_age) {
      //   this.errors.age = "Minimum age cannot be greater than maximum age";
      //   isValid = false;
      // }

      // If valid, perform the search
      if (isValid) {
        this.$router.push({ path: '/filter', query: { ...this.filters } });
      }
    },

   


  //  search() {
  //     // Navigate to filter-view with the filters as query parameters
  //     this.$router.push({ path: '/filter', query: { ...this.filters } });
  //   },

 

//     search() {
//   axios
//     .post("home/profiles/filter", {
//       gender: this.filters.gender,
//       min_age: this.filters.ageMin,
//       max_age: this.filters.ageMax,
//       district: this.filters.district,
//     })
//     .then((response) => {
//       if (response) {
        
//         this.filters.gender = "";
//         this.filters.ageMin = "";
//         this.filters.ageMax = "";
//         this.filters.district = "";

      
//         this.$router.push({ name: "filter-view" });
//       }
//     })
//     .catch((error) => {
     
//       this.errors = error.response?.data?.errors || "An error occurred.";
//     });
// },


    // search() {
    //   const query = {
    //     gender: this.filters.gender,
    //     min_age: this.filters.ageMin,
    //     max_age: this.filters.ageMax,
    //     district: this.filters.district,
    //   };

    //   Object.keys(query).forEach(
    //     (key) => query[key] === "" && delete query[key]
    //   );

    //   this.$router.push({ name: "filter-view", query });
    // },

    
  },

  mounted() {
    // Fetch profiles from the API when the component mounts
    this.getProfiles();
  },
};
</script>

<style scoped>
/* Profile card styling */
.profile-card {
  margin: 5px; /* Reduced gap between cards */
  border-radius: 6px;
  background-color: #f0f8ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.forum_1i2 {
  border: 1px solid #ddd;
  padding: 15px; /* Reduced padding */
}

.profile-card h6 a {
  color: #333;
  text-decoration: none;
}

.profile-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Avatar image styling */
.avatar img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}

/* Right-align the profile information and adjust margins */
.profile-info {
  margin-left: 15px;
}

/* Reduce space between the <p> tags */
.profile-info p {
  margin-bottom: 4px;
}

/* Adjust text size for a smaller card */
h6,
p {
  font-size: 13px;
}

.center1 {
  background: rgba(0, 0, 0, 0.3); /* Set the desired RGBA color */
}
</style>
