<template>
  <section id="terms" class="p-5 bg-light">
    <div class="container-xl">
        <h2 class="text-center mb-4">Terms & Conditions for Islamic Matrimony</h2>

        <h5>1. Introduction</h5>
        <p>Welcome to Islamic Matrimony. By accessing our website and using our services, you agree to comply with and be bound by the following Terms and Conditions. If you do not agree with these terms, please do not use our services.</p>

        <h5>2. Definitions</h5>
        <p>
            - <strong>“We”, “Us”, “Our”</strong> refers to Islamic Matrimony.<br>
            - <strong>“User”, “You”, “Your”</strong> refers to any individual or entity accessing or using our services.<br>
            - <strong>“Services”</strong> refers to the matrimonial services offered through our website.
        </p>

        <h5>3. Eligibility</h5>
        <p>To use our services, you must be at least 18 years old and of sound mind. By using our services, you confirm that you meet these eligibility requirements.</p>

        <h5>4. Account Registration</h5>
        <p>
            - You are required to create an account to access certain features of our services.<br>
            - You must provide accurate, current, and complete information during the registration process.<br>
            - You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </p>

        <h5>5. Code of Conduct</h5>
        <p>
            - Users must engage in respectful communication and interactions within our platform.<br>
            - Discriminatory, abusive, or offensive language and behavior are strictly prohibited.<br>
            - Users must not post false or misleading information about themselves or others.
        </p>

        <h5>6. Privacy Policy</h5>
        <p>Your privacy is important to us. Please refer to our <a href="#">Privacy Policy</a> for details on how we collect, use, and protect your personal information.</p>

        <h5>7. Intellectual Property</h5>
        <p>All content, trademarks, and other intellectual property on our website are owned by us or licensed to us. Users may not reproduce, distribute, or create derivative works from any content without our express written permission.</p>

        <h5>8. User Content</h5>
        <p>
            - You are solely responsible for any content you post on our platform.<br>
            - By posting content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, and distribute such content.
        </p>

        <h5>9. Limitation of Liability</h5>
        <p>
            - We do not guarantee the accuracy, reliability, or completeness of any information provided on our platform.<br>
            - To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of our services.
        </p>

        <h5>10. Indemnification</h5>
        <p>You agree to indemnify and hold us harmless from any claims, losses, damages, liabilities, and expenses (including attorney’s fees) arising from your use of our services or violation of these Terms and Conditions.</p>

        <h5>11. Termination</h5>
        <p>We reserve the right to terminate or suspend your account and access to our services at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or our services.</p>

        <h5>12. Governing Law</h5>
        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of our Jurisdiction. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in our Jurisdiction.</p>

        <h5>13. Changes to Terms</h5>
        <p>We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services after any changes constitutes your acceptance of the new Terms.</p>

        <h5>14. Contact Us</h5>
        <p>If you have any questions or concerns about these Terms and Conditions, please contact us.</p>
    </div>
</section>

</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
