<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <span class="btn toggle-btn">☰</span>
        <router-link class="nav-link navbar-brand p-0 me-4" aria-current="page" :to="{ name: 'home-view' }">
                  <img src="/assets/img/logo.svg" alt="Wedding Planner Logo" class="brand-image" style="max-height: 50px" />
              </router-link>
        <div class="d-flex ms-auto">
          <!-- Notification Icon -->
          <div class="dropdown">
            <a
              class="notification-icon"
              href="#"
              id="notificationDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-bell"></i>
              <span class="badge">3</span>
              <!-- Number of unread notifications -->
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end notification-dropdown"
              aria-labelledby="notificationDropdown"
            >
              <li class="notification-item unread">
                <i class="bi bi-exclamation-circle text-warning"></i>
                <div class="notification-content">
                  <p class="mb-0">New user registration</p>
                  <span class="notification-time">2 minutes ago</span>
                </div>
              </li>
              <li class="notification-item">
                <i class="bi bi-check-circle text-success"></i>
                <div class="notification-content">
                  <p class="mb-0">Monthly report generated</p>
                  <span class="notification-time">1 hour ago</span>
                </div>
              </li>
              <li class="notification-item">
                <i class="bi bi-info-circle text-info"></i>
                <div class="notification-content">
                  <p class="mb-0">Server maintenance scheduled</p>
                  <span class="notification-time">5 hours ago</span>
                </div>
              </li>
              <li>
                <a class="dropdown-item text-center" href="#"
                  >View all notifications</a
                >
              </li>
            </ul>
          </div>

          <!-- Profile Picture with Dropdown -->
          <div class="dropdown profile-section">
            <img
              src="https://via.placeholder.com/35"
              alt="Profile Picture"
              id="profileDropdown"
              data-bs-toggle="dropdown"
            />
            <span class="profile-name" data-bs-toggle="dropdown">John Doe</span>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="profileDropdown"
            >
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li><a class="dropdown-item" href="#">Settings</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  
</template>

<script>
export default {

}
</script>

<style>

</style>