<template>
  <section id="center" class="center_o bg_blue11 p_3">
    <div class="container-xl">
      <div class="row center_o1">
        <div class="col-md-12">
          <h2 class="text-white">Filter</h2>
          <h6 class="mb-0 mt-3 text-white">
            <a class="text-white" href="#">Home</a>
            <span class="mx-2 text-white">/</span> Filter
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section id="serv_pg" class="p_3 pt-4">
    <div class="container-xl">
      <div class="row serv_h1 mb-5 mt-3"></div>

      <div class="list_1r2">
        <div class="list_1r2i row mt-4" v-if="profiles.length">
          <div class="col-md-3 mt-2" v-for="profile in profiles" :key="profile.uuid">
            <div class="list_1r2im p-2 shadow_box">
              <div class="list_1r2im1 position-relative">
                <div class="list_1r2im1i">
                  <div class="grid clearfix">
                    <figure class="effect-jazz mb-0">
                      <a :href="'detail.html?uuid=' + profile.uuid">
                        <img
                          :src="profile.gender === 'male' ? '/assets/img/male.png' : '/assets/img/female.png'"
                          class="img-fluid"
                          alt="Profile Image"
                          style="width: 100%; height: 100%; object-fit: cover"
                        />
                      </a>
                    </figure>
                  </div>
                </div>
                <div class="list_1r2im1i1 position-absolute top-0 p-2">
                  <h6 class="d-inline-block bg_yell text-white rounded-3 p-2 fw-normal font_13 px-3">
                    Verified
                  </h6>
                </div>
              </div>
              <div class="list_1r2im2 pt-3">
                <h6>
                  <a :href="'user_detailsView.html?uuid=' + profile.uuid">{{ profile.name || 'Unknown Name' }}</a>
                </h6>
                <h6 class="font_14">
                  <i class="fa fa-calendar col_yell me-1"></i> {{ profile.age || 'N/A' }}
                  <span class="fw-normal">(Years)</span>
                </h6>
                <h6 class="font_14">
                  <i class="fas fa-arrows-alt-v col_yell me-2"></i> {{ profile.height ? profile.height + ' cm' : 'N/A' }}
                  <span class="fw-normal">(Height)</span>
                </h6>
                <h6 class="text-muted font_14 fw-normal">
                  <i class="fa fa-map-marker col_yell me-1"></i> {{ profile.district || 'N/A' }}, Bangladesh
                </h6>
              </div>
              <div class="list_1r2im2i row text-center mt-3">
                <div class="col-md-6">
                  <div class="list_1r2im2il">
                    <button class="btn btn-outline-primary btn-sm">
                      <i class="fa fa-heart fa-sm me-1"></i> Shortlist
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="list_1r2im2il">
                    <button class="btn btn-outline-secondary btn-sm">
                      <i class="fa fa-star fa-sm me-1"></i> Interest
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-else>No profiles found.</p>
      </div>

      <!-- Pagination -->
      <div class="pages text-center mt-4 row" v-if="pagination && pagination.total_items > pagination.items_per_page">
        <div class="col-12">
          <ul class="pagination justify-content-center mb-0 flex-wrap">
            <li class="page-item" :class="{ disabled: pagination.current_page === 1 }">
              <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pagination.current_page - 1)">
                <i class="fa fa-chevron-left"></i>
              </a>
            </li>
            <li
              v-for="page in totalPages"
              :key="page"
              class="page-item"
              :class="{ active: page === pagination.current_page }"
            >
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: pagination.current_page === pagination.last_page }">
              <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pagination.current_page + 1)">
                <i class="fa fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      profiles: [],
      pagination: {
        current_page: 1,
        last_page: 1,
        total_items: 0,
        items_per_page: 12,
      },
    };
  },
  computed: {
    totalPages() {
      return Array.from({ length: this.pagination.last_page }, (_, i) => i + 1);
    },
  },
  methods: {
    getProfiles(filters, page = 1) {
      axios
        .post(`home/profiles/filter?page=${page}`, filters)
        .then((response) => {
          if (response.data.success) {
            this.profiles = response.data.data;
            this.pagination = response.data.pagination || this.pagination; // Ensure pagination object is always defined
          } else {
            console.error("Failed to fetch profiles:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching profiles:", error);
        });
    },
    changePage(page) {
      if (page !== this.pagination.current_page && page > 0 && page <= this.pagination.last_page) {
        this.pagination.current_page = page;
        const filters = this.$route.query;
        this.getProfiles(filters, page);
      }
    },
  },
  mounted() {
    const filters = this.$route.query;
    this.getProfiles(filters);
  },
  watch: {
    $route(to) {
      const filters = to.query;
      this.getProfiles(filters);
    },
  },
};
</script>

<style>
/* Add any additional styles here */
</style>
