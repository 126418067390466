<template>
  <section id="center" class="center_o bg_blue11 p_3">
    <div class="container-xl">
      <div class="row center_o1">
        <div class="col-md-12">
          <h2 class="text-white">About Us</h2>
          <h6 class="mb-0 mt-3 text-white">
            <a class="text-white" href="#">Home</a>
            <span class="mx-2 text-white">/</span> About Us
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section id="about_pg" class="p_3">
    <div class="container-xl">
      <div class="row about_pg1">
        <div class="col-md-6">
          <div class="about_pg1l">
            <!-- <h6 class="col_yell">ABOUT US</h6> -->
            <h2 class="mt-4 mb-3">
              A Reliable Journey to Finding Partners Built on Islamic Values
            </h2>
            <p>
              Welcome to Islamic Matrimony. We specialize in bringing together
              Muslim singles in Bangladesh. Our mission is to create a
              trustworthy platform where individuals can find their life
              partners with ease.
            </p>
            <p>
              We focus on providing a simple and efficient matchmaking service.
              Our team works hard to ensure every profile is genuine. Join us
              and take the first step towards finding your perfect match.
            </p>
            <h6 class="mb-3"><a class="button_1" href="#">Contact</a></h6>
          </div>
        </div>
        <div class="col-md-6">
          <div class="about_pg1r">
            <div class="grid clearfix">
              <figure class="effect-jazz mb-0">
                <a href="#"
                  ><img src="/assets/img/12342.webp" class="w-100" alt="abc"
                /></a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="story" class="p_3 pt-5">
    <div class="container-xl">
      <div class="profile_1 row text-center mb-4">
        <div class="col-md-12">
          <h2>What Our Happy Clients Say</h2>
          <p class="mb-0">Discover heartfelt experiences from our satisfied clients.</p>
        </div>
      </div>
      <div class="story_1 row pt-5 pb-5">
        <div class="col-md-6">
          <div class="story_1l">
            <div class="story_1l1 position-relative">
              <div class="story_1l1i">
                <div class="grid clearfix">
                  <!-- <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img src="/assets/img/25.jpg" class="w-100" alt="client1"
                    /></a>
                  </figure> -->
                </div>
              </div>
              <div class="story_1l1i1 position-absolute text-end w-100 px-3">
                <!-- <img
                  src="/assets/img/8.jpg"
                  class="rounded-circle"
                  width="60"
                  height="60"
                  alt="client1"
                /> -->
              </div>
            </div>
            <div class="story_1l mt-4">
              <h3 class="mb-3">Fatima Noor</h3>
              <p>
                <i class="fs-1"></i>"Finding my life partner through this platform has been a beautiful journey. The support and understanding were exceptional, making the process seamless."
              </p>
              <h6 class="mb-0 col_yell fw-bold">Rajshahi, Bangladesh</h6>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="story_1l">
            <div class="story_1l1 position-relative">
              <div class="story_1l1i">
                <div class="grid clearfix">
                  <!-- <figure class="effect-jazz mb-0">
                    <a href="#"
                      ><img src="/assets/img/26.jpg" class="w-100" alt="client2"
                    /></a>
                  </figure> -->
                </div>
              </div>
              <div class="story_1l1i1 position-absolute text-end w-100 px-3">
                <!-- <img
                  src="/assets/img/9.jpg"
                  class="rounded-circle"
                  width="60"
                  height="60"
                  alt="client2"
                /> -->
              </div>
            </div>
            <div class="story_1l mt-4">
              <h3 class="mb-3">Omar Malik</h3>
              <p>
                <i class="fs-1"></i> "I never imagined that I would meet my soulmate through an online platform. The variety of profiles and the ease of communication were incredible."
              </p>
              <h6 class="mb-0 col_yell fw-bold">Dhaka, Bangladesh</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>

</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
