<template>

    <div class="p-rela"  >
        <TopbarView/>
        <sidebar-view/>
        <progressbar-view/>
        
       <router-view class="mt-5" > </router-view>
        <downbar-view/>

        <div class="p-center loader-company"  >
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
                    <span class="loading__dot_small"></span>
      </div>





 

    </div>
    
  
</template>

<script>
import TopbarView from '@/components/anonymous/partial/TopbarView.vue'
import DownbarView from '@/components/anonymous/partial/DownbarView.vue'

import ProgressbarView from '@/components/common/ProgressbarView.vue'

export default {
components:{
    TopbarView,
    DownbarView,
    ProgressbarView
    
},








}
</script>

<style>

</style>