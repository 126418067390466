<template>
  <section id="privacy-policy" class="p-5 bg-light">
    <div class="container-xl">
        <h2 class="text-center mb-4">Privacy Policy for Islamic Matrimony</h2>

        <p>Effective Date: 12/10/2024</p>

        <h5>1. Introduction</h5>
        <p>
            Welcome to Islamic Matrimony. We are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website and use our services. By using our services, you consent to the data practices described in this policy.
        </p>

        <h5>2. Information We Collect</h5>
        <p>We may collect the following types of information:</p>
        <ul>
            <li><strong>Personal Information:</strong> Information that you provide directly, such as your name, email address, phone number, and any other details you provide during account registration.</li>
            <li><strong>Usage Data:</strong> Information about how you use our website, including your IP address, browser type, pages visited, and the time and date of your visits.</li>
            <li><strong>Cookies:</strong> We may use cookies and similar tracking technologies to enhance your experience on our site. You can choose to accept or decline cookies through your browser settings.</li>
        </ul>

        <h5>3. How We Use Your Information</h5>
        <p>We may use your information for the following purposes:</p>
        <ul>
            <li>To provide, maintain, and improve our services.</li>
            <li>To communicate with you, including sending service-related information and updates.</li>
            <li>To personalize your experience and provide content and product offerings that interest you.</li>
            <li>To detect, prevent, and address technical issues.</li>
            <li>To comply with legal obligations and protect our rights and the rights of others.</li>
        </ul>

        <h5>4. Sharing Your Information</h5>
        <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
        <ul>
            <li><strong>Service Providers:</strong> Third parties that perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.</li>
            <li><strong>Compliance with Law:</strong> We may disclose your information if required by law or to respond to legal requests.</li>
            <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your personal information may be transferred as part of that transaction.</li>
        </ul>

        <h5>5. Data Security</h5>
        <p>We take reasonable measures to protect your personal information from unauthorized access, use, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is completely secure. Therefore, we cannot guarantee its absolute security.</p>

        <h5>6. Your Rights</h5>
        <p>You have the right to:</p>
        <ul>
            <li>Access, correct, or delete your personal information.</li>
            <li>Withdraw consent for us to process your personal information.</li>
            <li>Opt-out of marketing communications.</li>
        </ul>
        

        <h5>7. Third-Party Links</h5>
        <p>Our website may contain links to third-party websites. We do not control these sites and are not responsible for their content or privacy practices. We encourage you to review the privacy policies of any third-party sites you visit.</p>

        <h5>8. Changes to This Privacy Policy</h5>
        <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any changes.</p>

        <h5>9. Contact Us</h5>
        <p>If you have any questions about this Privacy Policy or our data practices, please contact us</p>
    </div>
</section>


</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
