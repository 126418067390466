<template>
  <div class="auth-page" >

    <!-- <div class="notification-popup hide">
      <p>
        <span class="task"></span>
        <span class="notification-text"></span>
      </p>
    </div> -->


        <router-view></router-view>



  </div>
       

 




</template>

<script>
export default {

}
</script>

<style>

</style>