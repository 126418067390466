<template>
  <section id="footer" class="p_3 bg_blue mt-1">
    <div class="container-xl">
      <!-- <div class="footer_2 row">
        <div class="col-md-12 text-center">
          <h6 class="text-white">Bridal - Your Personal Wedding Planner</h6>
          <p class="mb-0 text-white-50 mt-3">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries but also the leap into electronic typesetting, remaining essentially unchanged.
          </p>
        </div>
      </div> -->

      <div class="footer_3 row mt-4">
        <!-- About Us Section -->
        <!-- <div class="col-md-3">
          <div class="footer_about">
            <h6 class="text-white mb-3">About Us</h6>
            <p class="text-white-50">
              BangladeshiMatrimony.com is the No.1 most trusted matrimony site for Bangladeshi brides and grooms. Lakhs of members have successfully found their life partners here! Browse through our vast selection of profiles from all Religions... <a href="#" class="text-white">more »</a>
            </p>
          </div>
        </div> -->

        <div class="col-md-5">
  <div class="footer_3m" style="font-family: 'Montserrat', sans-serif;">
    <h6 class="text-white mb-3">About Us</h6>
    <p class=" list-unstyled text-white-50">
      Welcome to Islamic Matrimony. We specialize in bringing together
      Muslim singles in Bangladesh. Our mission is to create a
      trustworthy platform where individuals can find their life
      partners with ease.
      
      <!-- <a href="#" class="text-white">more »</a> -->
    </p>
  </div>
</div>


        <!-- Follow Us Section -->
        <div class="col-md-2">
          <div class="footer_3m">
            <h6 class="text-white mb-3">Useful Links</h6>
            <ul class="list-unstyled text-white">

            <li>
               <router-link class="text-white-50" aria-current="page" :to="{ name: 'T&D-view' }">
                  Terms & Conditions
              </router-link>
            </li>
            <li>
               <router-link class="text-white-50" aria-current="page" :to="{ name: 'privacy-policy-view' }">
                  Privacy Policy
              </router-link>
            </li>  


              <!-- <li><a class="text-white-50" href="#">Terms & Conditions</a></li> -->
              <!-- <li><a class="text-white-50" href="#">Privacy Policy</a></li> -->
            </ul>
          </div>
        </div>

        <!-- Help & Support Section -->
        <div class="col-md-2">
          <div class="footer_3m">
            <h6 class="text-white mb-3">Help & Support</h6>
            <ul class="list-unstyled text-white">
              <li><a class="text-white-50" href="#">Live Help</a></li>
              <li><a class="text-white-50" href="#">Contact Us</a></li>
              <li><a class="text-white-50" href="#">FAQs</a></li>
            </ul>
          </div>
        </div>

        <div class="col-md-3">
          <div class="footer_3m">
            <h6 class="text-white mb-3">Follow Us</h6>
            <div class="row footer_social_icons justify-content-left">
              <div class="col-auto">
                <a class="text-white-50" href="#">
                  <i class="fab fa-facebook fa-2x text-white-50 me-3"></i>
                </a>
              </div>
              <div class="col-auto">
                <a class="text-white-50" href="#">
                  <i class="fab fa-twitter fa-2x text-white-50 me-3"></i>
                </a>
              </div>
              <div class="col-auto">
                <a class="text-white-50" href="#">
                  <i class="fab fa-instagram fa-2x text-white-50 me-3"></i>
                </a>
              </div>
              <div class="col-auto">
                <a class="text-white-50" href="#">
                  <i class="fab fa-youtube fa-2x text-white-50 me-3"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="footer_b" class="pt-3 pb-3 bg_light">
    <div class="container-xl">
      <div class="footer_b1 row text-center">
        <div class="col-md-12">
          <p class="mb-0 text-white-50">
            © 2024 Islamic Matrimony. All Rights Reserved | Powered by
            <a
              class="text-white"
              href="https://www.datafluent.xyz/"
              target="_blank"
              >Datafluent Analytics</a
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
#footer {
  padding: 3rem 0;
  background-color: #1a1a1a; /* Adjust as needed */
}

.footer_2 {
  margin-bottom: 2rem;
}

.footer_3 {
  margin-top: 2rem;
}

.footer_about {
  margin-top: 1rem;
}

.footer_help {
  margin-top: 1rem;
}

.footer_help h6 {
  margin-bottom: 1rem;
}

.footer_help ul {
  padding-left: 0;
}

.footer_help li {
  list-style: none;
  margin: 0.5rem 0;
}

.col_yell {
  color: #f39c12; /* Adjust for your theme */
}

.input-group .form-control {
  border-radius: 0; /* Make it flat */
}

.input-group .btn {
  border-radius: 0; /* Make it flat */
}

.footer_social_icons i {
  color: #f1f1f1; /* Set default color for icons */
}

.footer_social_icons i:hover {
  color: #ffc107; /* Hover effect for icons */
}

.col_yell {
  color: #ffc107 !important; /* Apply yellow color to the icons */
}
</style>
