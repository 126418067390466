<template>
  <div class="page-wrapper">
    <div id="content">
      <div class="container-fluid mt-4">
        <!-- User Table Card -->
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <h5>User List</h5>
          </div>

          <div class="card-body">
            <!-- DataTable -->
            <DataTable
              :data="customers"
              class="table table-striped table-bordered custom-table"
            >
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>District</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
              <!-- Handled by DataTable itself -->
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <!-- View Modal -->
    <div
      class="modal fade"
      id="viewModal"
      tabindex="-1"
      aria-labelledby="viewModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewModalLabel">Customer Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section id="list" class="pb-4">
              <div class="container-xl">
                <div class="row list_1">
                  <div class="col-md-2">
                    <div class="list_1l">
                      <div class="list_1l1 mt-4 text-left text-center">
                        <!-- Profile Image with Square Frame centered -->
                        <div
                          class="profile-image-frame"
                          style="
                            border: 1px solid #ddd;
                            padding: 2px;
                            display: inline-block;
                            width: 180px;
                            height: 200px;
                          "
                        >
                          <img
                            src="/assets/img/profile.jpeg"
                            alt="Profile Image"
                            class="img-fluid"
                            style="width: 100%; height: 100%; object-fit: cover"
                          />
                        </div>
                      </div>

                      <!-- Profile Information aligned to left with icons -->
                      <div class="text-left">
                        <h6 class="mt-3">{{ personalInfos.name }}</h6>
                        <ul
                          class="mb-0 font_14 mt-3"
                          style="list-style-type: none; padding: 0"
                        >
                          <li class="d-flex align-items-center mb-2">
                            <i class="fa fa-calendar col_yell me-2"></i>
                            <span>{{ personalInfos.age }} (Years)</span>
                          </li>
                          <li class="d-flex align-items-center mb-2">
                            <i class="fa fa-arrows-alt-v col_yell me-3"></i>
                            <span>{{ demographicIinfos.height }} (Height)</span>
                          </li>
                          <li class="d-flex align-items-center mb-2">
                            <i class="fa fa-briefcase col_yell me-2"></i>
                            <span>{{ employmentInfos.designation }}</span>
                          </li>
                          <li class="d-flex align-items-center mb-2">
                            <i class="fa fa-map-marker col_yell me-2"></i>
                            <span
                              >{{ personalInfos.district }},
                              {{ personalInfos.lives_in }}</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-10">
                    <div class="list_1r">
                      <div class="list_1r1 mt-5 ml-2">
                        <!-- <h2>Profile</h2> -->
                        <!-- <p class="mb-0">Showing 9 results as per your search criteria</p> -->
                      </div>

                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue active"
                            id="personalviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#personalviews"
                            type="button"
                            role="tab"
                            aria-controls="personalviews"
                            aria-selected="true"
                          >
                            Personal Info
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue"
                            id="demographicviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#demographicviews"
                            type="button"
                            role="tab"
                            aria-controls="demographicviews"
                            aria-selected="false"
                          >
                            Demographic Info
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue"
                            id="educationviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#educationviews"
                            type="button"
                            role="tab"
                            aria-controls="educationviews"
                            aria-selected="false"
                          >
                            Education
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue"
                            id="employmentviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#employmentviews"
                            type="button"
                            role="tab"
                            aria-controls="employmentviews"
                            aria-selected="false"
                          >
                            Employment
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue"
                            id="religiousviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#religiousviews"
                            type="button"
                            role="tab"
                            aria-controls="religiousviews"
                            aria-selected="false"
                          >
                            Religious
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue"
                            id="maritalviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#maritalviews"
                            type="button"
                            role="tab"
                            aria-controls="maritalviews"
                            aria-selected="false"
                          >
                            Marital Status
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue"
                            id="refereesviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#refereesviews"
                            type="button"
                            role="tab"
                            aria-controls="refereesviews"
                            aria-selected="false"
                          >
                            Referees
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link col_blue"
                            id="perferenceviews-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#perferenceviews"
                            type="button"
                            role="tab"
                            aria-controls="perferenceviews"
                            aria-selected="false"
                          >
                            Perference
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content mt-3" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="personalviews"
                          role="tabpanel"
                          aria-labelledby="personalviews-tab"
                        >
                          <div class="mt-4 p-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h5>Personal Information</h5>
                              
                            </div>
                            <hr />

                            <div class="row mb-3">
                              <div class="col-md-4">
                                <strong>Name:</strong>&nbsp;
                                <span id="displayName">
                                  {{ personalInfos.name }}</span
                                >
                              </div>
                              <div class="col-md-4">
                                <strong>Father's Name:</strong>&nbsp;
                                <span id="displayFatherName">
                                  {{ personalInfos.father_name }}</span
                                >
                              </div>
                              <div class="col-md-4">
                                <strong>Mother's Name:</strong>&nbsp;
                                <span id="displayMotherName">
                                  {{ personalInfos.mother_name }}
                                </span>
                              </div>
                            </div>

                            <div class="row mb-3">
                              <div class="col-md-4">
                                <strong>Age:</strong>&nbsp;
                                <span id="displayAge">{{
                                  personalInfos.age
                                }}</span>
                              </div>
                              <div class="col-md-4">
                                <strong>Gender:</strong>&nbsp;
                                <span id="displayGender">{{
                                  personalInfos.gender
                                }}</span>
                              </div>
                              <div class="col-md-4">
                                <strong>Contact No:</strong>&nbsp;
                                <span id="displayContactNo">
                                  {{ personalInfos.contact_no }}</span
                                >
                              </div>
                            </div>

                            <div class="row mb-3">
                              <div class="col-md-4">
                                <strong>Address:</strong>&nbsp;
                                <span id="displayAddress">{{
                                  personalInfos.line_address
                                }}</span>
                              </div>
                              <div class="col-md-4">
                                <strong>Upazila:</strong>&nbsp;
                                <span id="displayUpazila">{{
                                  personalInfos.upazila
                                }}</span>
                              </div>
                              <div class="col-md-4">
                                <strong>District:</strong>&nbsp;
                                <span id="displayDistrict">{{
                                  personalInfos.district
                                }}</span>
                              </div>
                            </div>

                            <div class="row mb-3">
                              <div class="col-md-4">
                                <strong>Email:</strong>&nbsp;
                                <span id="displayEmail">
                                  {{ personalInfos.email }}</span
                                >
                              </div>
                              <div class="col-md-4">
                                <strong>NID Number:</strong>&nbsp;
                                <span id="displayNid">{{
                                  personalInfos.nid_no
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="demographicviews"
                          role="tabpanel"
                          aria-labelledby="demographicviews-tab"
                        >
                          <div class="mt-4 p-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h4>Demographic Information</h4>
                              
                            </div>
                            <hr />

                            <div class="row g-3 small-input-form mt-2 mb-3">
                              <!-- Height Field -->
                              <div class="col-md-4">
                                <strong>Height:</strong>&nbsp;
                                <span id="displayHeight"
                                  >{{ demographicIinfos.height }} (Height)</span
                                >
                                <!-- Example value -->
                              </div>

                              <!-- Skin Color Field -->
                              <div class="col-md-4">
                                <strong>Skin Color:</strong>&nbsp;
                                <span id="displaySkinColor">{{
                                  demographicIinfos.skin_color
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Hair Color Field -->
                              <div class="col-md-4">
                                <strong>Hair Color:</strong>&nbsp;
                                <span id="displayHairColor">{{
                                  demographicIinfos.hair_color
                                }}</span>
                                <!-- Example value -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="educationviews"
                          role="tabpanel"
                          aria-labelledby="educationviews-tab"
                        >
                          <div class="mt-4 p-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h4>Education Information</h4>
                             
                            </div>
                            <hr />

                            <div class="row g-3 small-input-form mt-4">
                              <!-- Highest Education Field -->
                              <div class="col-md-4">
                                <strong>Highest Education:</strong>&nbsp;
                                <span id="displayHighestEducation">{{
                                  educationalIinfos.education_level
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Institute Field -->
                              <div class="col-md-4">
                                <strong>Institute:</strong>&nbsp;
                                <span id="displayInstitute">{{
                                  educationalIinfos.institute
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Major Field -->
                              <div class="col-md-4">
                                <strong>Major:</strong>&nbsp;
                                <span id="displayMajor">{{
                                  educationalIinfos.major
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Passing Year Field -->
                              <div class="col-md-4">
                                <strong>Passing Year:</strong>&nbsp;
                                <span id="displayPassingYear">{{
                                  educationalIinfos.passing_year
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Certificate Upload -->
                              <!-- <div class="col-md-4">
                            <strong>Certificate:</strong>
                            <span id="displayCertificate"> Uploaded PDF</span>
                           
                          </div> -->
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="employmentviews"
                          role="tabpanel"
                          aria-labelledby="employmentviews-tab"
                        >
                          <div class="mt-4 p-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h4>Employment Information</h4>
                              
                            </div>
                            <hr />

                            <div class="row g-3 small-input-form mt-4">
                              <!-- Employment Status Field -->
                              <div class="col-md-4">
                                <strong>Employment Status:</strong>&nbsp;
                                <span id="displayEmploymentStatus">{{
                                  employmentInfos.employment_status
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Employment Type Field -->
                              <div class="col-md-4">
                                <strong>Employment Type:</strong>&nbsp;
                                <span id="displayEmploymentType">{{
                                  employmentInfos.employment_type
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Job Type Field -->
                              <div class="col-md-4">
                                <strong>Job Type:</strong>&nbsp;
                                <span id="displayJobType">{{
                                  employmentInfos.job_type
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Designation Field -->
                              <div class="col-md-4">
                                <strong>Designation:</strong>&nbsp;
                                <span id="displayDesignation">
                                  {{ employmentInfos.designation }}</span
                                >
                                <!-- Example value -->
                              </div>

                              <!-- Organization Field -->
                              <div class="col-md-4">
                                <strong>Organization:</strong>&nbsp;
                                <span id="displayOrganization">
                                  {{ employmentInfos.organization }}</span
                                >
                                <!-- Example value -->
                              </div>

                              <!-- Organization Type Field -->
                              <div class="col-md-4">
                                <strong>Organization Type:</strong>&nbsp;
                                <span id="displayOrganizationType">
                                  {{ employmentInfos.org_type }}</span
                                >
                                <!-- Example value -->
                              </div>

                              <!-- Job Experience Field -->
                              <div class="col-md-4">
                                <strong>Job Experience:</strong>&nbsp;
                                <span id="displayJobExperience"
                                  >{{
                                    employmentInfos.job_experience
                                  }}
                                  Years</span
                                >
                                <!-- Example value -->
                              </div>

                              <!-- Salary Range Field -->
                              <div class="col-md-4">
                                <strong>Salary Range:</strong>&nbsp;
                                <span id="displaySalaryRange">
                                  {{ employmentInfos.salary }}</span
                                >
                                <!-- Example value -->
                              </div>

                              <!-- Office ID Field -->
                              <!-- <div class="col-md-4">
                            <strong>Office ID:</strong>&nbsp;
                            <span id="displayOfficeID"> 123456</span>
                          </div> -->
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="religiousviews"
                          role="tabpanel"
                          aria-labelledby="religiousviews-tab"
                        >
                          <div class="mt-4 p-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h4>Religious Information</h4>
                              
                            </div>
                            <hr />

                            <div class="row g-3 small-input-form mt-4">
                              <!-- Religion Field -->
                              <div class="col-md-4">
                                <strong>Religion:</strong>&nbsp;
                                <span id="displayReligion">{{
                                  religiousInfos.religion
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Sect Field -->
                              <div class="col-md-4">
                                <strong>Sect:</strong>&nbsp;
                                <span id="displaySect">{{
                                  religiousInfos.sect
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Say 5 Time Prayer Field -->
                              <div class="col-md-4">
                                <strong>Say 5 Time Prayer:</strong>&nbsp;
                                <span id="displaySay5TimePrayer">{{
                                  religiousInfos.pray_5_times
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Wear Burqa Field -->
                              <div class="col-md-4">
                                <strong>Wear Burqa:</strong>&nbsp;
                                <span id="displayWearBurqa">{{
                                  religiousInfos.wear_burka
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Can Recite Quran Field -->
                              <div class="col-md-4">
                                <strong>Can Recite Quran:</strong>&nbsp;
                                <span id="displayCanReciteQuran">{{
                                  religiousInfos.recit_quran
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Read Quran Field -->
                              <div class="col-md-4">
                                <strong>Read Quran:</strong>&nbsp;
                                <span id="displayReadQuran">{{
                                  religiousInfos.read_quaran_daily
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Follows Sharia Rule Field -->
                              <div class="col-md-4">
                                <strong>Follows Sharia Rule:</strong>&nbsp;
                                <span id="displayFollowsSharia">{{
                                  religiousInfos.follow_sharia_rule
                                }}</span>
                                <!-- Example value -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="maritalviews"
                          role="tabpanel"
                          aria-labelledby="maritalviews-tab"
                        >
                          <div class="mt-4 p-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h4>Marital Status</h4>
                              <!-- <a href="#" class="btn btn-link" title="Edit">
                                <i
                                  class="fa fa-edit"
                                  style="font-size: 20px"
                                ></i>
                              </a> -->
                            </div>
                            <hr />

                            <div class="row g-3 small-input-form mt-4">
                              <!-- Status Field -->
                              <div class="col-md-4">
                                <strong>Status:</strong>&nbsp;
                                <span id="displayStatus">{{
                                  maritalInfos.marital_status
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Has Children Field -->
                              <div class="col-md-4">
                                <strong>Has Children:</strong>&nbsp;
                                <span id="displayHasChildren">{{
                                  maritalInfos.has_children
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Number of Children Field -->
                              <div class="col-md-4">
                                <strong>If Yes, How many:</strong>&nbsp;
                                <span id="displayChildrenNumber">{{
                                  maritalInfos.no_children
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <!-- Age of Children Field -->
                              <div class="col-md-4">
                                <strong>Age of Children:</strong>&nbsp;
                                <span id="displayChildrenAge">{{
                                  maritalInfos.age_of_first
                                }}</span>
                                <!-- Example value -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="refereesviews"
                          role="tabpanel"
                          aria-labelledby="refereesviews-tab"
                        >
                          <div class="mt-4 p-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h4>Referees</h4>
                              <!-- <a href="#" class="btn btn-link" title="Edit">
                                <i
                                  class="fa fa-edit"
                                  style="font-size: 20px"
                                ></i>
                              </a> -->
                            </div>
                            <hr />

                            <div class="row g-3 small-input-form mt-4">
                              <!-- Referee 1 -->
                              <div class="col-md-4">
                                <strong>Name:</strong>&nbsp;
                                <span id="referee1NameDisplay">{{
                                  refereesInfos.name
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4">
                                <strong>Designation:</strong>&nbsp;
                                <span id="referee1DesignationDisplay">{{
                                  refereesInfos.designation
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4">
                                <strong>Organization:</strong>&nbsp;
                                <span id="referee1OrganizationDisplay">{{
                                  refereesInfos.organization
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4">
                                <strong>Mobile Number:</strong>&nbsp;
                                <span id="referee1MobileDisplay">{{
                                  refereesInfos.mobile_number
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4">
                                <strong>Email:</strong>&nbsp;
                                <span id="referee1EmailDisplay">{{
                                  refereesInfos.email_id
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4">
                                <strong>Relation with Candidate:</strong>&nbsp;
                                <span id="referee1RelationDisplay">{{
                                  refereesInfos.relation_with_candidate
                                }}</span>
                                <!-- Example value -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade"
                          id="perferenceviews"
                          role="tabpanel"
                          aria-labelledby="perferenceviews-tab"
                        >
                          <div class="mt-4 m-2">
                            <div
                              class="d-flex justify-content-between align-items-center mb-3"
                            >
                              <h4>Perference</h4>
                              <!-- <a href="#" class="btn btn-link" title="Edit">
                                <i
                                  class="fa fa-edit"
                                  style="font-size: 20px"
                                ></i>
                              </a> -->
                            </div>
                            <hr />

                            <div class="row g-3 small-input-form mt-4">
                              <!-- Referee 1 -->
                              <div class="col-md-4">
                                <strong>Age Range:</strong>&nbsp;
                                <span id="ageRangeDisplay">
                                  {{
                                    (preferanceInfos?.age_range?.age_min ??
                                      "null") +
                                    " - " +
                                    (preferanceInfos?.age_range?.age_max ??
                                      "null") +
                                    " Years"
                                  }}
                                </span>
                              </div>

                              <div class="col-md-4">
                                <strong>Education Level:</strong>&nbsp;
                                <span id="educationLevelDisplay">
                                  {{
                                    preferanceInfos?.minimum_education_level ??
                                    "null"
                                  }}
                                </span>
                              </div>

                              <!-- <div class="col-md-4">
                                <strong>Employment Type:</strong>&nbsp;
                                <span id="employmentTypeDisplay">{{ preferanceInfos. }}</span>
                              
                              </div> -->
                              <div class="col-md-4">
                                <strong>Salary Range:</strong>&nbsp;
                                <span id="salaryRangeDisplay">{{
                                  preferanceInfos?.minimum_salary ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4">
                                <strong>Marital Status:</strong>&nbsp;
                                <span id="maritalStatusDisplay">{{
                                  preferanceInfos?.marital_status ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4">
                                <strong>Home District:</strong>&nbsp;
                                <span id="homeDistrictDisplay">{{
                                  preferanceInfos?.home_district ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <div class="col-md-4 mt-3">
                                <strong>Lives in:</strong>&nbsp;
                                <span id="LivesDisplay">{{
                                  preferanceInfos?.lives_in ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4 mt-3">
                                <strong>Read Quran:</strong>&nbsp;
                                <span id="ReadQuranDisplay">{{
                                  preferanceInfos?.read_quaran_daily ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4 mt-3">
                                <strong>Religion:</strong>&nbsp;
                                <span id="ReligionDisplay">{{
                                  preferanceInfos?.religion ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4 mt-3">
                                <strong>Sect:</strong>&nbsp;
                                <span id="SectDisplay">{{
                                  preferanceInfos?.sect ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4 mt-3">
                                <strong>Say 5 Time Prayer:</strong>&nbsp;
                                <span id="PrayerDisplay">{{
                                  preferanceInfos?.pray_5_times ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                              <div class="col-md-4 mt-3">
                                <strong>Wear Burqa:</strong>&nbsp;
                                <span id="BurqaDisplay">{{
                                  preferanceInfos?.wear_burka ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <div class="col-md-4 mt-3">
                                <strong>Can Recite Quran:</strong>&nbsp;
                                <span id="ReciteQuranDisplay">{{
                                  preferanceInfos?.recit_quran ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>

                              <div class="col-md-4 mt-3">
                                <strong>Follows Sharia Rule:</strong>&nbsp;
                                <span id="ShariaDisplay">{{
                                  preferanceInfos?.follow_sharia_rule ?? "null"
                                }}</span>
                                <!-- Example value -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <!-- Verify / Reject Buttons -->

                <!-- <button @click="verifyCustomer(customer.uuid)">Verify Customer</button> -->

                <button
                  class="btn btn-success"
                  
                @click="verifyCustomer(customer.uuid)"
                >
                  Verified
                </button>
                <button
                  class="btn btn-danger ms-2"
                  @click="verifyCustomer(2)"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";

// Use DataTablesCore
DataTable.use(DataTablesCore);

export default {
  components: { DataTable },
  data() {
    return {
      customers: [], // Data array for customers
      selectedCustomer: {}, // Data for selected customer in the view modal
      customerss: {},
      uuid: "",
      personalInfos: {},
      demographicIinfos: {},
      educationalIinfos: {},
      employmentInfos: {},
      maritalInfos: {},
      refereesInfos: {},
      religiousInfos: {},
      preferanceInfos: {},
      customerStatus: "",
      customer: [],
    };
  },

  methods: {
    getCustomers() {
      axios
        .get("/admin/profile/list")
        .then((response) => {
          if (response) {
            const customers = response.data.data;
            this.customers = [];

            for (let customer of customers) {
              var uuid = customer.uuid;
              const id = customer.user_id;
              const name = customer.name || "N/A";
              const age = customer.age || "N/A";
              const gender = customer.gender || "N/A";
              const district = customer.district || "N/A";

              // Set status column
              let status = "";
              if (customer.status === "0") {
                status = `<span class="badge bg-warning">Pending</span>`;
              } else if (customer.status === "1") {
                status = `<span class="badge bg-success">Accepted</span>`;
              } else if (customer.status === "2") {
                status = `<span class="badge bg-danger">Rejected</span>`;
              }

              var action =
                "<a href='#' onclick='handleClick(" +
                '"' +
                uuid +
                '"' +
                ")' ' data-bs-toggle='modal' data-bs-target='#viewModal'><i class='fas fa-eye'></i></a>   <a href='#' onclick='deletePopUp(" +
                '"' +
                uuid +
                '"' +
                ")' '  data-bs-toggle='modal' data-bs-target='#delete_client'><i class=' ms-3 far fa-trash-alt'></i></a> ";

              this.customers.push([
                id,
                name,
                age,
                gender,
                district,
                status,
                action,
              ]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // viewCustomer(uuid) {

    //   const customer = this.customers.find((customer) => customer[0] === uuid);
    //   if (customer) {
    //     this.selectedCustomer = {
    //       uuid,
    //       name: customer[1],
    //       age: customer[2],
    //       gender: customer[3],
    //       district: customer[4],
    //       status: customer[5],
    //     };
    //   }
    // },

    // handleClick(uuid) {
    //   axios
    //     .get("/admin/profile/customer/details/" + uuid)
    //     .then((response) => {
    //       if (response) {
    //         this.customerss = response.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    // verifyCustomer(uuid) {
    //   axios
    //     .post(`/admin/profile/customer/verification/${uuid}`, {
    //       customerId: this.customerId, 
    //     })
    //     .then((response) => {
    //       if (response) {
    //         this.verificationResult = response.data; 

            
    //         this.getVerificationStatus();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);

    //     });
    // },

    handleClick(uuid) {
      axios
        .get("/admin/profile/customer/details/" + uuid)
        .then((response) => {
          if (response && response.data.data.length > 0) {
            const customer = response.data.data[0];

            this.personalInfos = JSON.parse(customer.personal_infos);
            this.demographicIinfos = JSON.parse(customer.demographic_infos);
            this.educationalIinfos = JSON.parse(customer.educational_infos);
            this.employmentInfos = JSON.parse(customer.employment_infos);
            this.maritalInfos = JSON.parse(customer.marital_infos);
            this.refereesInfos = JSON.parse(customer.referees_infos);
            this.religiousInfos = JSON.parse(customer.religious_infos);
            this.preferanceInfos = JSON.parse(customer.preferance_infos);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    verifyCustomer(uuid) {
      axios
        .post("/admin/profile/customer/verification/", {
          is_verified: 1, // Set verification status
          uuid: uuid, // Send the customer's UUID
        })
        .then((response) => {
          if (response.data.success) {
            alert(response.data.message); // Display success message
            this.getCustomers(); // Refresh the customer list
          } else {
            this.errors = response.data.message; // Handle error message
          }
        })
        .catch((error) => {
          this.errors = error.response ? error.response.data.errors : "An error occurred";
          console.log("Error verifying customer:", error);
        });
    },


//     verifyCustomer(status) {
//   axios
//     .post("/admin/profile/customer/verification/" + this.customer.uuid, {
//       is_verified: status
//     })
//     .then((response) => {
//       if (response) {
//         alert(response.data.message); // Assuming the API returns a message
//         this.handleClick(this.customer.uuid); // Reload customer data if needed
//       }
//     })
//     .catch((error) => {
//       this.errors = error.response ? error.response.data.errors : "An error occurred";
//     });
// },




//     verifyCustomer(uuid, status) {
//     if (!uuid) {
//         console.error("UUID is undefined. Cannot proceed with verification.");
//         return;
//     }
//     axios
//         .put(`/admin/profile/customer/verification/${uuid}`, {
//             is_verified: status
//         })
//         .then((response) => {
//             if (response) {
//                 this.handleClick(uuid); // Reload details if needed
                
//             }
//         })
//         .catch((error) => {
//             console.log(error);
//         });
// },


    //  updateCustomerStatus(status) {
    //     axios
    //       .post('/api/admin/profile/customer/status', {

    //         status: status,
    //       })
    //       .then((response) => {
    //         // Update customerStatus based on the status sent
    //         if (status === 0) {
    //           this.customerStatus = 'Pending';
    //         } else if (status === 1) {
    //           this.customerStatus = 'Accepted';
    //         } else if (status === 2) {
    //           this.customerStatus = 'Rejected';
    //         }
    //         console.log('Status updated successfully:', response.data);
    //       })
    //       .catch((error) => {
    //         console.error('Error updating customer status:', error);
    //       });
    //   },

    //   updateCustomerVerification(uuid, status) {
    //     console.log("Updating UUID:", uuid);
    //     const payload = {
    //         uuid,
    //         is_verified: status,
    //     };

    //     console.log("Payload:", payload);

    //     axios
    //       .post("/admin/profile/customer/status", payload)
    //       .then((response) => {
    //           if (response.data.success) {

    //               alert("Customer verification status updated successfully.");
    //           } else {
    //               alert("Failed to update customer verification status.");
    //           }
    //       })
    //       .catch((error) => {
    //           console.error("Error updating customer verification:", error);
    //           alert("An error occurred while updating the customer verification status.");
    //       });
    // },

    updateCustomerVerification(uuid, status) {
      console.log("Updating UUID:", uuid);
      const payload = {
        uuid,
        is_verified: status,
      };

      console.log("Payload:", payload);

      axios
        .post("/admin/profile/customer/status", payload)
        .then((response) => {
          if (response.data.success) {
            alert("Customer verification status updated successfully.");
          } else {
            alert("Failed to update customer verification status.");
          }
        })
        .catch((error) => {
          console.error("Error updating customer verification:", error);
          alert(
            "An error occurred while updating the customer verification status."
          );
        });
    },

    // verifyCustomer(customer) {
    //   // Make sure customer is passed correctly
    //   console.log('Customer passed to verifyCustomer:', customer);

    //   // Make the API request to verify the customer
    //   axios.post(`/admin/profile/customer/status/${customer.uuid}`)
    //     .then(response => {
    //       // Check if the API call was successful
    //       if (response.data.success) {
    //         // Update the verification status of the specific customer
    //         customer.is_verified = '1'; // Assuming '1' indicates verified
    //         alert('Customer verified successfully!');
    //       } else {
    //         alert('Verification failed: ' + response.data.message);
    //       }
    //     })
    //     .catch(error => {
    //       console.error("Verification Error: ", error);
    //       alert('Verification failed due to an error!');
    //     });
    // },

    deletePopUp(uuid) {
      // Logic to handle customer deletion confirmation
      console.log("Delete customer:", uuid);
    },

    // updatedepartment(status) {
    //   axios
    //     .post("/api/admin/profile/customer/status/" + this.uuid, {
    //       status: status,
    //     })
    //     .then((response) => {
    //       if (response) {
    //         if (status === 1) {
    //         this.customerStatus = 'Accepted';
    //       } else if (status === 2) {
    //         this.customerStatus = 'Rejected';
    //       }
    //       console.log('Status updated successfully:', response.data);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    // verifyCustomer(uuid) {
    //   axios
    //     .post(`/api/admin/profile/customer/status/${uuid}`, { is_verified: "1", status: "1" })
    //     .then(() => {
    //       this.getCustomers(); // Reload customer details after verification
    //       alert("Customer verified successfully");
    //     })
    //     .catch((error) => {
    //       console.log("Error verifying customer:", error);
    //       alert("An error occurred while verifying the customer");
    //     });
    // },

    //   verifyCustomer(uuid) {
    //   axios
    //     .put(`/api/admin/profile/customer/update/${uuid}`)
    //     .then(() => {
    //       this.getCustomers(); // Reload customer details after verification
    //       alert("Customer verified successfully");
    //     })
    //     .catch((error) => {
    //       console.log("Error verifying customer:", error);
    //       alert("An error occurred while verifying the customer");
    //     });
    // },

    // verifyCustomer(uuid) {
    //   axios
    //     .put(`/admin/profile/verify/${uuid}`)
    //     .then(() => {
    //       this.getCustomers();
    //       alert("Customer verified successfully");
    //     })
    //     .catch((error) => {
    //       console.log("Error verifying customer:", error);
    //     });
    // },

    // rejectCustomer(uuid) {
    //   axios
    //     .put(`/admin/profile/reject/${uuid}`)
    //     .then(() => {
    //       this.getCustomers();
    //       alert("Customer rejected successfully");
    //     })
    //     .catch((error) => {
    //       console.log("Error rejecting customer:", error);
    //     });
    // },
  },

  mounted() {
    this.getCustomers();
    window.handleClick = this.handleClick;
  },
};
</script>

<style>
@import "datatables.net-bs5"; /* Import DataTable styles */
</style>
