<template>
  <div class="container mt-5 mb-5">
    <!-- Tabs navigation -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link col_yell active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Dashboard
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link col_yell"
          id="match-tab"
          data-bs-toggle="tab"
          data-bs-target="#match"
          type="button"
          role="tab"
          aria-controls="match"
          aria-selected="false"
        >
          My Match
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link col_yell"
          id="contact-tab"
          data-bs-toggle="tab"
          data-bs-target="#contact"
          type="button"
          role="tab"
          aria-controls="contact"
          aria-selected="false"
        >
          Search Match
        </button>
      </li>

      <li class="nav-item" role="presentation">
        <button
          class="nav-link col_yell"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          My Profile
        </button>
      </li>

      <li class="nav-item" role="presentation">
        <button
          class="nav-link col_yell"
          id="profile_view-tab"
          data-bs-toggle="tab"
          data-bs-target="#profileview"
          type="button"
          role="tab"
          aria-controls="profileview"
          aria-selected="false"
        >
          Profile View & Edit
        </button>
      </li>
    </ul>

    <!-- Tab content -->
    <div class="tab-content mt-3" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <section id="list" class="pb-4">
          <div class="container-xl">
            <div class="row list_1">
              <div class="col-md-2">
                <div class="list_1l">
                  <div class="list_1l1 mt-4">
                    <h5>Interested</h5>
                    <hr />
                    <ul>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Pandding</a>
                        <span
                          id="pending-count"
                          class="badge bg-secondary text-end"
                          >0</span
                        >
                      </li>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Accepted</a>
                        <span
                          id="accepted-count"
                          class="badge bg-success text-end"
                          >0</span
                        >
                      </li>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Rejected</a>
                        <span
                          id="rejected-count"
                          class="badge bg-danger text-end"
                          >0</span
                        >
                      </li>
                    </ul>
                  </div>

                  <div class="list_1l1 mt-4">
                    <h5>Request</h5>
                    <hr />

                    <ul>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Received</a>
                        <span
                          id="received-count"
                          class="badge bg-success text-end"
                          >0</span
                        >
                      </li>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Sent</a>
                        <span id="sent-count" class="badge bg-danger text-end"
                          >0</span
                        >
                      </li>
                    </ul>

                    <!-- <h6 class="mb-0 mt-4 center_sm"><a class="button" href="detail.html">Filter</a></h6> -->
                  </div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="list_1r">
                  <div class="list_1r1 text-center p-4">
                    <p class="font-weight-bold">
                      قَالَ النَّبِيُّ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ: «إِذَا
                      تَزَوَّجَ الْعَبْدُ، فَقَدِ اسْتَكْمَلَ نِصْفَ الدِّينِ،
                      فَلْيَتَّقِ اللَّهَ فِي النِّصْفِ الْبَاقِي
                    </p>
                    <p>
                      নবী করিম (সাল্লাল্লাহু আলাইহি ওয়া সাল্লাম) বলেছেন, “যখন
                      কোনো ব্যক্তি বিয়ে করে, সে তার দ্বীনের অর্ধেক পূর্ণ করে।
                      বাকি অর্ধেকে আল্লাহকে ভয় করো।”
                    </p>
                  </div>

                  <div class="plan_1 row pb-4">
                    <div class="col-md-4">
                      <div class="plan_1i shadow_box p-4 px-3">
                        <div class="plan_1i1 row">
                          <div class="col-md-2">
                            <div class="plan_1i1l">
                              <span class="fs-1 col_yell lh-1">
                                <i class="fa fa-check-circle"></i>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-10">
                            <div class="plan_1i1r">
                              <h5 class="fs-6">Total Accepted</h5>

                              <h6 class="mb-0">
                                <a class="col_yell" href="#">150</a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="plan_1i shadow_box p-4 px-3">
                        <div class="plan_1i1 row">
                          <div class="col-md-2">
                            <div class="plan_1i1l">
                              <span class="fs-1 col_yell lh-1"
                                ><i class="fa fa-times-circle"></i
                              ></span>
                            </div>
                          </div>
                          <div class="col-md-10">
                            <div class="plan_1i1r">
                              <h5 class="fs-6">Total Rejected</h5>

                              <h6 class="mb-0">
                                <a class="col_yell" href="#">80</a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="plan_1i shadow_box p-4 px-3">
                        <div class="plan_1i1 row">
                          <div class="col-md-2">
                            <div class="plan_1i1l">
                              <span class="fs-1 col_yell lh-1"
                                ><i class="fa fa-clock"></i
                              ></span>
                            </div>
                          </div>
                          <div class="col-md-10">
                            <div class="plan_1i1r">
                              <h5 class="fs-6">Total Pending</h5>

                              <h6 class="mb-0">
                                <a class="col_yell" href="#">30</a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="list_1r1 mt-4">
                    <h2>Recommended Match</h2>
                    <!-- <p class="mb-0">Showing 9 results as per your search criteria</p> -->
                  </div>
                  <div class="list_1r2">
                    <div class="list_1r2i row mt-4">
                      <div
                        class="col-md-3 mt-2"
                        v-for="customer in customers"
                        :key="customer.uuid"
                      >
                        <div class="list_1r2im p-2 shadow_box">
                          <div class="list_1r2im1 position-relative">
                            <div class="list_1r2im1i">
                              <div class="grid clearfix">
                                <figure class="effect-jazz mb-0">
                                  <a
                                    :href="'detail.html?uuid=' + customer.uuid"
                                  >
                                    <img
                                      :src="
                                        customer.gender === 'male'
                                          ? '/assets/img/male.png'
                                          : '/assets/img/female.png'
                                      "
                                      class="img-fluid"
                                      alt="Profile Image"
                                      style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                      "
                                    />
                                  </a>
                                </figure>
                              </div>
                            </div>
                            <!-- <div class="list_1r2im1i1 position-absolute top-0 p-2">
            <h6 class="d-inline-block bg_yell text-white rounded-3 p-2 fw-normal font_13 px-3">
              Verified
            </h6>
          </div> -->
                            <div
                              class="list_1r2im1i2 position-absolute px-2"
                              style="bottom: 10px; right: 10px"
                            >
                              <span
                                class="d-inline-block text-white bg_yell rounded-circle fs-5"
                                style="
                                  width: 30px;
                                  height: 30px;
                                  text-align: center;
                                "
                              >
                                <i class="fa fa-check"></i>
                              </span>
                            </div>
                          </div>
                          <div class="list_1r2im2 pt-3">
                            <h6>
                              <a
                                :href="
                                  'user_detailsView.html?uuid=' + customer.uuid
                                "
                                >{{ customer.name }}</a
                              >
                            </h6>
                            <h6 class="font_14">
                              <i class="fa fa-calendar col_yell me-1"></i>
                              {{ customer.age }}
                              <span class="fw-normal">(Years)</span>
                            </h6>
                            <h6 class="font_14">
                              <i class="fas fa-arrows-alt-v col_yell me-2"></i>
                              {{ customer.height }} cm
                              <span class="fw-normal">(Height)</span>
                            </h6>
                            <h6 class="text-muted font_14 fw-normal">
                              <i class="fa fa-map-marker col_yell me-1"></i>
                              {{ customer.district }}, {{ customer.lives_in }}
                            </h6>
                            <ul class="mb-0 mt-2 text-center">
                              <li class="d-inline-block">
                                <a :href="'detail.html?uuid=' + customer.uuid"
                                  >View Full Details</a
                                >
                              </li>
                            </ul>
                            <div class="list_1r2im2i row text-center mt-3">
                              <div class="col-6 col-md-6 mb-2 mb-md-0">
                                <button
                                  class="btn btn-outline-primary btn-sm w-100"
                                >
                                  <i class="fa fa-heart fa-sm me-1"></i>
                                  Shortlist
                                </button>
                              </div>
                              <div class="col-6 col-md-6">
                                <button
                                  class="btn btn-outline-secondary btn-sm w-100"
                                >
                                  <i class="fa fa-star fa-sm me-1"></i> Interest
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Display error messages -->
                      <div v-if="errors" class="alert alert-danger">
                        {{ errors }}
                      </div>
                    </div>
                  </div>
                  <div class="pages text-center mt-4 row">
                    <div class="col-12">
                      <ul
                        class="pagination justify-content-center mb-0 flex-wrap"
                      >
                        <li class="page-item">
                          <a
                            class="page-link"
                            href="detail.html"
                            aria-label="Previous"
                          >
                            <i class="fa fa-chevron-left"></i>
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link act" href="detail.html">1</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="detail.html">2</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="detail.html">3</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="detail.html">4</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="detail.html">5</a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="detail.html">6</a>
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            href="detail.html"
                            aria-label="Next"
                          >
                            <i class="fa fa-chevron-right"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        class="tab-pane fade"
        id="match"
        role="tabpanel"
        aria-labelledby="match-tab"
      >
        <section id="list" class="p_3">
          <div class="container-xl">
            <div class="row list_1">
              <div class="col-md-3">
                <div class="list_1l">
                  <div class="list_1l1">
                    <h3 class="mb-3">Filter By</h3>
                    <!-- <h5>VENUE TYPE</h5>
                      <hr /> -->
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Recommended
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Newest Profile
                      </label>
                    </div>
                  </div>

                  <div class="list_1l1 mt-4">
                    <h5>Age</h5>
                    <hr />

                    <!-- Age Filter Form -->
                    <form class="d-flex align-items-center">
                      <!-- From Age Input -->
                      <input
                        type="number"
                        class="form-control form-control-sm me-1"
                        id="fromAge"
                        placeholder="From"
                        min="0"
                        max="100"
                        style="width: 100px; height: 30px"
                      />

                      <span class="mx-1">To</span>

                      <!-- To Age Input -->
                      <input
                        type="number"
                        class="form-control form-control-sm me-2"
                        id="toAge"
                        placeholder="To"
                        min="0"
                        max="100"
                        style="width: 100px; height: 30px"
                      />

                      <!-- Submit Button in h6 -->
                      <h6
                        class="mb-0 mt-0 center_sm"
                        style="display: inline-block"
                      >
                        <a
                          class="button btn text-center"
                          href="detail.html"
                          id="submitButton"
                        >
                          Submit
                        </a>
                      </h6>
                    </form>
                  </div>

                  <div class="list_1l1 mt-4">
                    <h5>Height</h5>
                    <hr />

                    <!-- Age Filter Form -->
                    <form class="d-flex align-items-center">
                      <!-- From Age Input -->
                      <input
                        type="number"
                        class="form-control form-control-sm me-1"
                        id="fromAge"
                        placeholder="From"
                        min="0"
                        max="100"
                        style="width: 100px; height: 30px"
                      />

                      <span class="mx-1">To</span>

                      <!-- To Age Input -->
                      <input
                        type="number"
                        class="form-control form-control-sm me-2"
                        id="toAge"
                        placeholder="To"
                        min="0"
                        max="100"
                        style="width: 100px; height: 30px"
                      />

                      <!-- Submit Button in h6 -->
                      <h6
                        class="mb-0 mt-0 center_sm"
                        style="display: inline-block"
                      >
                        <a
                          class="button btn text-center"
                          href="detail.html"
                          id="submitButton"
                        >
                          Submit
                        </a>
                      </h6>
                    </form>
                  </div>

                  <!-- <div class="list_1l2 mt-4">
                      <h5>VENUE BUDGET</h5>
                      <hr />
                      <ul class="mb-0">
                        <li class="d-inline-block">
                          <a href="detail.html">Less than 1 Lakh</a>
                        </li>
                        <li class="d-inline-block">
                          <a href="detail.html">1-2 Lakh</a>
                        </li>
                        <li class="d-inline-block">
                          <a href="detail.html">2-3 Lakh</a>
                        </li>
                        <li class="d-inline-block">
                          <a href="detail.html">3-4 Lakh</a>
                        </li>
                        <li class="d-inline-block">
                          <a href="detail.html">4-5 Lakh</a>
                        </li>
                      </ul>
                    </div> -->
                  <div class="list_1l1 mt-4">
                    <h5>Marital Status</h5>
                    <hr />
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Unmarried
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Married
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Divorced
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Widow/ Widower
                      </label>
                    </div>
                    <!-- <div class="form-check mt-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Music allowed late
                        </label>
                      </div> -->
                  </div>

                  <div class="list_1l1 mt-4">
                    <h5>Education</h5>
                    <hr />
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Masters
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Bachelors
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        School/College
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Others
                      </label>
                    </div>
                  </div>

                  <div class="list_1l1 mt-4">
                    <h5>Employed in</h5>
                    <hr />
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Government
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Private
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Others
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Others
                      </label>
                    </div>
                  </div>

                  <div class="list_1l1 mt-4">
                    <h5>Occupation</h5>
                    <hr />
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Students
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Doctor
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Engineer
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Teacher
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Others
                      </label>
                    </div>
                  </div>
                  <div class="list_1l1 mt-4">
                    <h5>Sect</h5>
                    <hr />
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Sunni
                      </label>
                    </div>
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Hanafi
                      </label>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Others
                      </label>
                    </div>
                    <h6 class="mb-0 mt-4 center_sm">
                      <a class="button" href="detail.html">Filter</a>
                    </h6>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="list_1r">
                  <div class="list_1r1">
                    <h2>Matching</h2>
                    <p class="mb-0">
                      <!-- Showing 9 results as per your search criteria -->
                    </p>
                  </div>
                  <div class="list_1r2">
                    <div class="list_1r2i row mt-4">
                      <div v-if="profiles.length > 0" class="row">
                        <div
                          class="col-md-3 mt-2"
                          v-for="profile in profiles"
                          :key="profile.uuid"
                        >
                          <div class="list_1r2im p-2 shadow_box">
                            <div class="list_1r2im1 position-relative">
                              <div class="list_1r2im1i">
                                <div class="grid clearfix">
                                  <figure class="effect-jazz mb-0">
                                    <a
                                      :href="'detail.html?uuid=' + profile.uuid"
                                    >
                                      <img
                                        :src="
                                          profile.gender === 'male'
                                            ? '/assets/img/male.png'
                                            : '/assets/img/female.png'
                                        "
                                        class="img-fluid rounded w-100"
                                        alt="Profile Image"
                                      />
                                    </a>
                                  </figure>
                                </div>
                              </div>
                              <div
                                class="list_1r2im1i1 position-absolute top-0 p-2"
                              >
                                <h6
                                  class="d-inline-block bg_yell text-white rounded-3 p-2 fw-normal font_13 px-3"
                                >
                                  Verified
                                </h6>
                              </div>
                              <div
                                class="list_1r2im1i2 position-absolute px-2"
                                style="bottom: 10px; right: 10px"
                              >
                                <span
                                  class="d-inline-block text-white bg_yell rounded-circle"
                                  style="
                                    width: 40px;
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                  "
                                >
                                  <i
                                    class="fa fa-check"
                                    style="font-size: 20px"
                                  ></i>
                                </span>
                              </div>
                            </div>
                            <div class="list_1r2im2 pt-3">
                              <h6>
                                <a
                                  :href="
                                    'user_detailsView.html?uuid=' + profile.uuid
                                  "
                                  >{{ profile.name }}</a
                                >
                              </h6>
                              <h6 class="font_14">
                                <i class="fa fa-calendar col_yell me-1"></i>
                                {{ profile.age }}
                                <span class="fw-normal">(Years)</span>
                              </h6>
                              <h6 class="font_14">
                                <i
                                  class="fas fa-arrows-alt-v col_yell me-2"
                                ></i>
                                {{ profile.height }} cm
                                <span class="fw-normal">(Height)</span>
                              </h6>
                              <h6 class="text-muted font_14 fw-normal">
                                <i class="fa fa-map-marker col_yell me-1"></i>
                                {{ profile.district }}, {{ profile.lives_in }}
                              </h6>
                              <ul class="mb-0 mt-2 text-center">
                                <li class="d-inline-block">
                                  <a :href="'detail.html?uuid=' + profile.uuid"
                                    >View Full Details</a
                                  >
                                </li>
                              </ul>
                              <div class="list_1r2im2i row text-center mt-3">
                                <div class="col-6 col-md-6 mb-2 mb-md-0">
                                  <button
                                    class="btn btn-outline-primary btn-sm w-100"
                                  >
                                    <i class="fa fa-heart fa-sm me-1"></i>
                                    Shortlist
                                  </button>
                                </div>
                                <div class="col-6 col-md-6">
                                  <button
                                    class="btn btn-outline-secondary btn-sm w-100"
                                  >
                                    <i class="fa fa-star fa-sm me-1"></i>
                                    Interest
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Display error message if no profiles are found -->
                      <div v-else class="alert alert-danger">
                        {{ errorsmessage }}
                      </div>
                    </div>
                  </div>
                  <div class="pages text-end mt-4 row">
                    <div class="col-md-12">
                      <ul class="mb-0">
                        <li>
                          <a href="detail.html"
                            ><i class="fa fa-chevron-left"></i
                          ></a>
                        </li>
                        <li><a class="act" href="detail.html">1</a></li>
                        <li><a href="detail.html">2</a></li>
                        <li><a href="detail.html">3</a></li>
                        <li><a href="detail.html">4</a></li>
                        <li><a href="detail.html">5</a></li>
                        <li><a href="detail.html">6</a></li>
                        <li>
                          <a href="detail.html"
                            ><i class="fa fa-chevron-right"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        class="tab-pane fade"
        id="contact"
        role="tabpanel"
        aria-labelledby="contact-tab"
      >
        <div class="container mt-5">
          <h2>Search for Match</h2>
          <hr />
          <form class="row g-3 mb-5">
            <!-- First Row -->
            <div class="col-md-4">
              <label for="age" class="form-label">Age</label>
              <select class="form-select form-select-sm" id="age">
                <option selected>Select Age</option>
                <option value="21-30">18-30</option>
                <option value="31-40">31-40</option>
                <option value="41-50">41-50</option>
                <option value="51-60">51-60</option>
                <option value="61-70">61-70</option>
                <option value="71-80">71-80</option>
                <option value="81-90">81-90</option>
                <option value="91-100">91-100</option>
              </select>
            </div>

            <div class="col-md-4">
              <label for="height" class="form-label">Height (cm)</label>
              <select class="form-select form-select-sm" id="height">
                <option selected>Select Height</option>
                <option value="140-150">140-150</option>
                <option value="151-160">151-160</option>
                <option value="161-170">161-170</option>
                <option value="171-180">171-180</option>
                <option value="181-190">181-190</option>
                <option value="191-200">191-200</option>
              </select>
            </div>

            <div class="col-md-4">
              <label for="maritalStatus" class="form-label"
                >Marital Status</label
              >
              <select class="form-select form-select-sm" id="maritalStatus">
                <option selected>Select Status</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
              </select>
            </div>

            <!-- Second Row -->
            <div class="col-md-4">
              <label for="religion" class="form-label">Religion</label>
              <select class="form-select form-select-sm" id="religion">
                <option selected>Select Religion</option>
                <option value="islam">Islam</option>
                <option value="hinduism">Hinduism</option>
                <option value="christianity">Christianity</option>
                <option value="buddhism">Buddhism</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div class="col-md-4">
              <label for="sect" class="form-label">Sect</label>
              <select class="form-select form-select-sm" id="sect">
                <option selected>Select Sect</option>
                <option value="sect1">Sunni</option>
                <option value="sect2">Hanafi</option>
                <option value="sect3">Others</option>
              </select>
            </div>

            <div class="col-md-4">
              <label for="education" class="form-label">Education</label>
              <select class="form-select form-select-sm" id="education">
                <option selected>Select Education Level</option>
                <option value="highschool">High School</option>
                <option value="bachelor">Bachelor's</option>
                <option value="master">Master's</option>
                <option value="phd">PhD</option>
              </select>
            </div>

            <!-- Third Row -->

            <div class="col-md-4">
              <label for="district" class="form-label">District</label>
              <select class="form-select form-select-sm" id="education">
                <option selected>Select District</option>
                <option value="dhaka">Dhaka</option>
                <option value="chittagong">Chittagong</option>
                <option value="rajshahi">Rajshahi</option>
                <option value="rangpur">Rangpur</option>
                <option value="barisal">Barisal</option>
                <option value="sylhet">Sylhet</option>
              </select>
            </div>

            <div class="col-md-4">
              <label for="education" class="form-label">Country</label>
              <select class="form-select form-select-sm" id="education">
                <option selected>Select Country</option>
                <option value="bangladesh">Bangladesh</option>
                <option value="india">India</option>
                <option value="pakistan">Pakistan</option>
                <option value="nepal">Nepal</option>
                <option value="afghanistan">Afghanistan</option>
                <option value="china">China</option>
              </select>
            </div>

            <div class="col-md-4">
              <label for="gender" class="form-label">Gender</label>
              <select class="form-select form-select-sm" id="gender">
                <option selected>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <!-- Submit Button -->
            <div class="col-md-4">
              <h6 class="mb-0 mt-4 center_sm">
                <a class="button" href="detail.html">Search</a>
              </h6>
            </div>
          </form>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <section id="list" class="pb-4">
          <div class="container-xl">
            <div class="row list_1">
              <div class="col-md-2">
                <div class="list_1l">
                  <div class="list_1l1 mt-4">
                    <h5>Interested</h5>
                    <hr />
                    <ul>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Pandding</a>
                        <span
                          id="pending-count"
                          class="badge bg-secondary text-end"
                          >0</span
                        >
                      </li>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Accepted</a>
                        <span
                          id="accepted-count"
                          class="badge bg-success text-end"
                          >0</span
                        >
                      </li>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Rejected</a>
                        <span
                          id="rejected-count"
                          class="badge bg-danger text-end"
                          >0</span
                        >
                      </li>
                    </ul>
                  </div>

                  <div class="list_1l1 mt-4">
                    <h5>Request</h5>
                    <hr />

                    <ul>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Received</a>
                        <span
                          id="received-count"
                          class="badge bg-success text-end"
                          >0</span
                        >
                      </li>
                      <li class="mt-1 d-flex justify-content-between">
                        <a href="#">Sent</a>
                        <span id="sent-count" class="badge bg-danger text-end"
                          >0</span
                        >
                      </li>
                    </ul>

                    <h6 class="mb-0 mt-4 center_sm">
                      <a class="button" href="detail.html">Filter</a>
                    </h6>
                  </div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="list_1r">
                  <div class="list_1r1 mt-4 ml-2">
                    <h2>Update Profile</h2>
                    <!-- <p class="mb-0">Showing 9 results as per your search criteria</p> -->
                  </div>

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue active"
                        id="personal-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#personal"
                        type="button"
                        role="tab"
                        aria-controls="personal"
                        aria-selected="true"
                      >
                        Personal Info
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="demographic-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#demographic"
                        type="button"
                        role="tab"
                        aria-controls="demographic"
                        aria-selected="false"
                      >
                        Demographic Info
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="education1-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#education1"
                        type="button"
                        role="tab"
                        aria-controls="education1"
                        aria-selected="false"
                      >
                        Education
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="employment-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#employment"
                        type="button"
                        role="tab"
                        aria-controls="employment"
                        aria-selected="false"
                      >
                        Employment
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="religious-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#religious"
                        type="button"
                        role="tab"
                        aria-controls="religious"
                        aria-selected="false"
                      >
                        Religious
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="marital-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#marital"
                        type="button"
                        role="tab"
                        aria-controls="marital"
                        aria-selected="false"
                      >
                        Marital Status
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="referees-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#referees"
                        type="button"
                        role="tab"
                        aria-controls="referees"
                        aria-selected="false"
                      >
                        Referees
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="perference-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#perference"
                        type="button"
                        role="tab"
                        aria-controls="perference"
                        aria-selected="false"
                      >
                        Perference
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content mt-3" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="personal"
                      role="tabpanel"
                      aria-labelledby="personal-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h5>Personal Information</h5>
                        <hr />
                        <form class="row g-3 small-input-form">
                          <!-- Name, Father's Name, Mother's Name -->
                          <div class="col-md-4">
                            <label for="name" class="form-label">Name</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="name"
                              v-model="name"
                              placeholder="Enter Name"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="fatherName" class="form-label"
                              >Father's Name</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="father_name"
                              v-model="father_name"
                              placeholder="Enter Father's Name"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="motherName" class="form-label"
                              >Mother's Name</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="mother_name"
                              v-model="mother_name"
                              placeholder="Enter Mother's Name"
                            />
                          </div>

                          <!-- Age, Gender -->
                          <div class="col-md-2">
                            <label for="age" class="form-label">Age</label>
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="age"
                              v-model="age"
                              min="0"
                              max="120"
                              placeholder="Enter Age"
                            />
                          </div>

                          <div class="col-md-2">
                            <label for="gender" class="form-label"
                              >Gender</label
                            >
                            <select
                              class="form-select form-select-sm"
                              id="gender"
                              v-model="gender"
                            >
                              <option selected disabled>Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </select>
                          </div>

                          <!-- Address, Upazila, District -->
                          <div class="col-md-4">
                            <label for="address" class="form-label"
                              >Address</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="line_address"
                              v-model="line_address"
                              placeholder="Enter Address"
                            />
                          </div>

                          <div class="col-md-2">
                            <label for="upazila" class="form-label"
                              >Upazila</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="upazila"
                              v-model="upazila"
                              placeholder="Enter Upazila"
                            />
                          </div>

                          <div class="col-md-2">
                            <label for="district" class="form-label"
                              >District</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="district"
                              v-model="district"
                              placeholder="Enter District"
                            />
                          </div>

                          <!-- Contact No, Email, NID Number -->
                          <div class="col-md-4">
                            <label for="contactNo" class="form-label"
                              >Contact No.</label
                            >
                            <input
                              type="tel"
                              class="form-control form-control-sm"
                              id="contactNo"
                              v-model="contact_no"
                              placeholder="Enter Contact Number"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="email" class="form-label">Email</label>
                            <input
                              type="email"
                              class="form-control form-control-sm"
                              id="email"
                              v-model="email"
                              placeholder="Enter Email"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="nid" class="form-label"
                              >NID Number</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="nid_no"
                              v-model="nid_no"
                              placeholder="Enter NID Number"
                            />
                          </div>

                          <!-- Guardian Details -->
                          <div class="col-md-4">
                            <label for="guardianName" class="form-label"
                              >Guardian Name</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="guardian_name"
                              v-model="guardian_name"
                              placeholder="Enter Guardian's Name"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="relationGuardian" class="form-label"
                              >Relation with Guardian</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="relation_guardian"
                              v-model="relation_guardian"
                              placeholder="Enter Relation with Guardian"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="guardianPhone" class="form-label"
                              >Guardian Phone No.</label
                            >
                            <input
                              type="tel"
                              class="form-control form-control-sm"
                              id="guardian_mobile"
                              v-model="guardian_mobile"
                              placeholder="Enter Guardian's Phone Number"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="guardianEmail" class="form-label"
                              >Guardian Email</label
                            >
                            <input
                              type="email"
                              class="form-control form-control-sm"
                              id="guardian_email"
                              v-model="guardian_email"
                              placeholder="Enter Guardian's Email"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="guardianNid" class="form-label"
                              >Guardian NID</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="guardian_nid"
                              v-model="guardian_nid"
                              placeholder="Enter Guardian's NID"
                            />
                          </div>

                          <!-- Lives in, Country Name (if Abroad), Number of Siblings -->
                          <div class="col-md-4">
                            <label for="livesIn" class="form-label"
                              >Lives in</label
                            >
                            <select
                              class="form-select form-select-sm"
                              id="lives_in"
                              v-model="lives_in"
                            >
                              <option selected disabled>
                                Bangladesh/Abroad
                              </option>
                              <option value="bangladesh">Bangladesh</option>
                              <option value="abroad">Abroad</option>
                            </select>
                          </div>

                          <div
                            class="col-md-4"
                            id="countrySection"
                            style="display: none"
                          >
                            <label for="countryName" class="form-label"
                              >Country Name (if abroad)</label
                            >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="country_name_if_abroad"
                              v-model="country_name_if_abroad"
                              placeholder="Enter Country Name"
                            />
                          </div>

                          <div class="col-md-4">
                            <label for="siblings" class="form-label"
                              >Number of Siblings</label
                            >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="number_of_sibling"
                              v-model="number_of_sibling"
                              min="0"
                              max="20"
                              placeholder="Enter Number of Siblings"
                            />
                          </div>

                          <!-- Image Upload -->
                          <div class="col-md-4">
                            <label for="image" class="form-label">Image</label>
                            <input
                              type="file"
                              class="form-control form-control-sm"
                              id="image"
                              accept="image/*"
                            />
                          </div>

                          <!-- Submit Button -->

                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="addPersonalInfo"
                              class="button button111"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="demographic"
                      role="tabpanel"
                      aria-labelledby="demographic-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h4>Demographic Information</h4>
                        <form>
                          <div class="row g-3 small-input-form mt-4">
                            <!-- Height Field -->
                            <div class="col-md-4">
                              <label for="height" class="form-label"
                                >Height (cm)</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                id="height"
                                v-model="height"
                                placeholder="Height"
                              />
                            </div>

                            <!-- Skin Color Field -->
                            <div class="col-md-4">
                              <label for="skinColor" class="form-label"
                                >Skin Color</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="skin_color"
                                placeholder="Skin Color"
                              />
                            </div>
                            <!-- Hair Color Field -->
                            <div class="col-md-4">
                              <label for="hairColor" class="form-label"
                                >Hair Color</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                v-model="hair_color"
                                placeholder="Hair Color"
                              />
                            </div>
                          </div>

                          <!-- Submit Button -->
                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="addDemographicInfo"
                              class="button button111"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="education1"
                      role="tabpanel"
                      aria-labelledby="education1-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h4>Education Information</h4>
                        <form>
                          <div class="row g-3 small-input-form mt-4">
                            <!-- Highest Education Field -->
                            <div class="col-md-4">
                              <label for="highestEducation" class="form-label"
                                >Highest Education</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="education_level"
                                v-model="education_level"
                                placeholder="Enter Highest Education"
                              />
                            </div>
                            <!-- Institute Field -->
                            <div class="col-md-4">
                              <label for="institute" class="form-label"
                                >Institute</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="institute"
                                v-model="institute"
                                placeholder="Enter Institute"
                              />
                            </div>

                            <!-- Major Field -->
                            <div class="col-md-4">
                              <label for="major" class="form-label"
                                >Major</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="major"
                                v-model="major"
                                placeholder="Enter Major"
                              />
                            </div>

                            <!-- Passing Year Field -->
                            <div class="col-md-4">
                              <label for="passingYear" class="form-label"
                                >Passing Year</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                id="passing_year"
                                v-model="passing_year"
                                placeholder="YYYY"
                                min="1900"
                                max="2024"
                                required
                              />
                            </div>

                            <!-- Certificate Upload (PDF only) -->
                            <!-- <div class="col-md-4">
                              <label for="certificate" class="form-label"
                                >Certificate (PDF only)</label
                              >
                              <input
                                type="file"
                                class="form-control form-control-sm"
                                id="certificate"
                                accept="application/pdf"
                                required
                              />
                            </div> -->
                          </div>

                          <!-- Submit Button -->
                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="addEducationInfo"
                              class="button button111"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="employment"
                      role="tabpanel"
                      aria-labelledby="employment-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h4>Employment Information</h4>
                        <form>
                          <div class="row g-3 small-input-form mt-4">
                            <!-- Employment Status Field (Yes/No) -->
                            <div class="col-md-4">
                              <label for="employmentStatus" class="form-label"
                                >Employment Status</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="employment_status"
                                v-model="employment_status"
                              >
                                <option value="">
                                  Select Employment Status
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <!-- Employment Type Field -->
                            <div class="col-md-4">
                              <label for="employmentType" class="form-label"
                                >Employment Type</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="employment_type"
                                v-model="employment_type"
                              >
                                <option value="">Select Employment Type</option>
                                <option value="Government">Government</option>
                                <option value="NGO">NGO</option>
                                <option value="Private">Private</option>
                              </select>
                            </div>

                            <!-- Job Type Field -->
                            <div class="col-md-4">
                              <label for="jobType" class="form-label"
                                >Job Type</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="job_type"
                                v-model="job_type"
                              >
                                <option value="">Select Job Type</option>
                                <option value="Permanent">Permanent</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Contractual">Contractual</option>
                              </select>
                            </div>

                            <!-- Designation Field -->
                            <div class="col-md-4">
                              <label for="designation" class="form-label"
                                >Designation</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="designation"
                                v-model="designation"
                                placeholder="Enter Designation"
                              />
                            </div>

                            <!-- Organization Field -->
                            <div class="col-md-4">
                              <label for="organization" class="form-label"
                                >Organization</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="organization"
                                v-model="organization"
                                placeholder="Enter Organization Name"
                              />
                            </div>

                            <!-- Organization Type Field -->
                            <div class="col-md-4">
                              <label for="organizationType" class="form-label"
                                >Organization Type</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="org_type"
                                v-model="org_type"
                                placeholder="Enter Organization Type"
                              />
                            </div>

                            <!-- Job Experience Field -->
                            <div class="col-md-4">
                              <label for="jobExperience" class="form-label"
                                >Job Experience</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="job_experience"
                                v-model="job_experience"
                                placeholder="Enter Job Experience (Years)"
                              />
                            </div>

                            <!-- Salary Range Field -->
                            <div class="col-md-4">
                              <label for="salaryRange" class="form-label"
                                >Salary Range</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="salary"
                                v-model="salary"
                                placeholder="Enter Salary Range"
                              />
                            </div>

                            <!-- Office ID Field -->
                            <!-- <div class="col-md-4">
                              <label for="officeID" class="form-label"
                                >Office ID (For verification)</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="officeID"
                                placeholder="Enter Office ID"
                                required
                              />
                            </div> -->
                          </div>

                          <!-- Submit Button -->
                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="addEmploymentInfo"
                              class="button"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="religious"
                      role="tabpanel"
                      aria-labelledby="religious-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h4>Religious Information</h4>
                        <form>
                          <div class="row g-3 small-input-form mt-4">
                            <!-- Religion Field -->
                            <div class="col-md-4">
                              <label for="religion" class="form-label"
                                >Religion</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="religion"
                                v-model="religion"
                                placeholder="Enter Religion"
                              />
                            </div>
                            <!-- Sect Field -->
                            <div class="col-md-4">
                              <label for="sect" class="form-label">Sect</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="sect"
                                v-model="sect"
                                placeholder="Enter Sect"
                              />
                            </div>

                            <!-- Say 5 Time Prayer Field -->
                            <div class="col-md-4">
                              <label for="say5TimePrayer" class="form-label"
                                >Say 5 Time Prayer</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="pray_5_times"
                                v-model="pray_5_times"
                              >
                                <option value="">Select Yes/No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            <!-- Wear Burqa Field -->
                            <div class="col-md-4">
                              <label for="wearBurqa" class="form-label"
                                >Wear Burqa</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="wear_burka"
                                v-model="wear_burka"
                              >
                                <option value="">Select Yes/No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <!-- Can Recite Quran Field -->
                            <div class="col-md-4">
                              <label for="canReciteQuran" class="form-label"
                                >Can Recite Quran</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="recit_quran"
                                v-model="recit_quran"
                              >
                                <option value="">Select Yes/No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <!-- Read Quran Field -->
                            <div class="col-md-4">
                              <label for="readQuran" class="form-label"
                                >Read Quran</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="read_quaran_daily"
                                v-model="read_quaran_daily"
                              >
                                <option value="">
                                  Select Reading Frequency
                                </option>
                                <option value="Everyday">Everyday</option>
                                <option value="2-3 times in a week">
                                  2-3 times in a week
                                </option>
                                <option value="Once a month">
                                  Once a month
                                </option>
                                <option value="Not get time to read">
                                  Not get time to read
                                </option>
                              </select>
                            </div>

                            <!-- Follows Sharia Rule Field -->
                            <div class="col-md-4">
                              <label for="followsSharia" class="form-label"
                                >Follows Sharia Rule</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="follow_sharia_rule"
                                v-model="follow_sharia_rule"
                              >
                                <option value="">Select Yes/No</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>

                          <!-- Submit Button -->
                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="addReligiousInfo"
                              class="button button111"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="marital"
                      role="tabpanel"
                      aria-labelledby="marital-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h4>Marital Status</h4>
                        <form>
                          <div class="row g-3 small-input-form mt-4">
                            <!-- Status Field -->
                            <div class="col-md-4">
                              <label for="status" class="form-label"
                                >Status</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="marital_status"
                                v-model="marital_status"
                              >
                                <option value="">Select Status</option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Married but spouse died">
                                  Married but spouse died
                                </option>
                              </select>
                            </div>

                            <!-- Has Children Field -->
                            <div class="col-md-4">
                              <label for="hasChildren" class="form-label"
                                >Has Children</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="has_children"
                                v-model="has_children"
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            <!-- Number of Children Field -->
                            <div class="col-md-4">
                              <label for="childrenNumber" class="form-label"
                                >If Yes, How many</label
                              >
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                id="no_children"
                                v-model="no_children"
                                placeholder="Enter number of children"
                                min="0"
                              />
                            </div>

                            <!-- Age of Children Field -->
                            <div class="col-md-4">
                              <label for="childrenAge" class="form-label"
                                >Age of Children</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="age_of_first"
                                v-model="age_of_first"
                                placeholder="Enter age of children"
                              />
                            </div>
                          </div>

                          <!-- Submit Button -->
                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="addMaritalInfo"
                              class="button button111"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="referees"
                      role="tabpanel"
                      aria-labelledby="referees-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h4>Referees</h4>
                        <form>
                          <!-- Referee 1 -->
                          <div class="row g-3 small-input-form mt-4">
                            <div class="col-md-4">
                              <label for="referee1Name" class="form-label"
                                >Name</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="name"
                                v-model="name"
                                placeholder="Enter name"
                              />
                            </div>
                            <div class="col-md-4">
                              <label
                                for="referee1Designation"
                                class="form-label"
                                >Designation</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="designation"
                                v-model="designation"
                                placeholder="Enter designation"
                              />
                            </div>
                            <div class="col-md-4">
                              <label
                                for="referee1Organization"
                                class="form-label"
                                >Organization</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="organization"
                                v-model="organization"
                                placeholder="Enter organization"
                              />
                            </div>

                            <div class="col-md-4">
                              <label for="referee1Mobile" class="form-label"
                                >Mobile Number</label
                              >
                              <input
                                type="tel"
                                class="form-control form-control-sm"
                                id="mobile_number"
                                v-model="mobile_number"
                                placeholder="Enter mobile number"
                              />
                            </div>
                            <div class="col-md-4">
                              <label for="referee1Email" class="form-label"
                                >Email</label
                              >
                              <input
                                type="email"
                                class="form-control form-control-sm"
                                id="email_id"
                                v-model="email_id"
                                placeholder="Enter email"
                              />
                            </div>
                            <div class="col-md-4">
                              <label for="referee1Relation" class="form-label"
                                >Relation with Candidate</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="relation_with_candidate"
                                v-model="relation_with_candidate"
                                placeholder="Enter relation"
                              />
                            </div>
                          </div>

                          <!-- Submit Button -->
                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="addRefereesInfo"
                              class="button button111"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="perference"
                      role="tabpanel"
                      aria-labelledby="perference-tab"
                    >
                      <div class="container mt-4 mb-3">
                        <h4>Perference</h4>
                        <form>
                          <!-- Age Range -->
                          <!-- <div class="row g-3 small-input-form mt-4">
            
        </div> -->

                          <!-- Employment Type and Salary Range -->
                          <div class="row g-3 small-input-form mt-4">
                            <div class="col-md-4">
                              <label for="ageRange" class="form-label"
                                >Age Range</label
                              >
                              <div class="input-group">
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="age_min"
                                  v-model="age_min"
                                  placeholder="Min Age"
                                  aria-label="Minimum Age"
                                />
                                <span
                                  style="
                                    padding-left: 10px;
                                    padding-right: 10px;
                                  "
                                  >to</span
                                >
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  id="age_max"
                                  v-model="age_max"
                                  placeholder="Max Age"
                                  aria-label="Maximum Age"
                                />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <label for="educationLevel" class="form-label"
                                >Education Level</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="minimum_education_level"
                                v-model="minimum_education_level"
                                placeholder="Enter education level"
                              />
                            </div>

                            <div class="col-md-4">
                              <label for="salaryRange" class="form-label"
                                >Salary Range</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="minimum_salary"
                                v-model="minimum_salary"
                                placeholder="Enter salary range"
                              />
                            </div>

                            <!-- <div class="col-md-4">
                              <label for="employmentType" class="form-label"
                                >Employment Type</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="minimum_salary"
                                v-model="minimum_salary"
                                placeholder="Enter employment type"
                              />
                            </div> -->

                            <div class="col-md-4">
                              <label for="maritalStatus" class="form-label"
                                >Marital Status</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="marital_status"
                                v-model="marital_status"
                                placeholder="Enter marital status"
                              />
                            </div>
                            <div class="col-md-4">
                              <label for="homeDistrict" class="form-label"
                                >Home District</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="home_district"
                                v-model="home_district"
                                placeholder="Enter home district"
                              />
                            </div>

                            <div class="col-md-4">
                              <label for="livesIn1" class="form-label"
                                >Lives in</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="lives_in"
                                v-model="lives_in"
                              >
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Abroad">Abroad</option>
                              </select>
                            </div>

                            <div class="col-md-4">
                              <label for="religion" class="form-label"
                                >Religion</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="religion"
                                v-model="religion"
                              >
                                <option selected>Select Religion</option>
                                <option value="Islam">Islam</option>
                                <option value="Hinduism">Hinduism</option>

                                <option value="Other">Other</option>
                              </select>
                            </div>

                            <div class="col-md-4">
                              <label for="sect" class="form-label">Sect</label>
                              <select
                                class="form-select form-select-sm"
                                id="sect"
                                v-model="sect"
                              >
                                <option selected>Select Sect</option>
                                <option value="Sunni">Sunni</option>
                                <option value="Hanafi">Hanafi</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>

                            <div class="col-md-4">
                              <label for="sect" class="form-label"
                                >Say 5 Time Prayer</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="pray_5_times"
                                v-model="pray_5_times"
                              >
                                <option selected>Select option</option>
                                <option value="Yes">Yes</option>
                                <option value="No">NO</option>
                                <option value="Try to pray">Try to pray</option>
                              </select>
                            </div>

                            <div class="col-md-4">
                              <label for="sect" class="form-label"
                                >Wear Burqa</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="wear_burka"
                                v-model="wear_burka"
                              >
                                <option selected>Select option</option>
                                <option value="Yes">Yes</option>
                                <option value="No">NO</option>
                              </select>
                            </div>

                            <div class="col-md-4">
                              <label for="sect" class="form-label"
                                >Can Recite Quran</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="recit_quran"
                                v-model="recit_quran"
                              >
                                <option selected>Select option</option>
                                <option value="Yes">Yes</option>
                                <option value="No">NO</option>
                              </select>
                            </div>

                            <div class="col-md-4">
                              <label for="readQuran" class="form-label"
                                >Read Quran</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="read_quaran_daily"
                                v-model="read_quaran_daily"
                              >
                                <option value="Everyday">Everyday</option>
                                <option value="2-3 times in a week">
                                  2-3 times in a week
                                </option>
                                <option value="Once a month">
                                  Once a month
                                </option>
                                <option value="Not get time to read">
                                  Not get time to read
                                </option>
                              </select>
                            </div>

                            <!-- <div
                              class="col-md-4 small-input-form"
                              id="countryNameDiv"
                              style="display: none"
                            >
                              <label for="countryName" class="form-label"
                                >Country Name</label
                              >
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                id="countryName"
                                placeholder="Enter country name"
                              />
                            </div> -->

                            <div class="col-md-4">
                              <label for="sect" class="form-label"
                                >Follows Sharia Rule</label
                              >
                              <select
                                class="form-select form-select-sm"
                                id="follow_sharia_rule"
                                v-model="follow_sharia_rule"
                              >
                                <option selected>Select option</option>
                                <option value="Yes">Yes</option>
                                <option value="NO">NO</option>
                              </select>
                            </div>
                          </div>

                          <!-- Submit Button -->
                          <h6 class="mb-0 mt-4 center_sm">
                            <button
                              @click.prevent="AddPreferencesInfos"
                              class="button button111"
                              type="button"
                            >
                              Save
                            </button>
                          </h6>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        class="tab-pane fade"
        id="profileview"
        role="tabpanel"
        aria-labelledby="profile_view-tab"
      >
        <section id="list" class="pb-4">
          <div class="container-xl">
            <div class="row list_1">
              <div class="col-md-2">
                <div class="list_1l">
                  <div class="list_1l1 mt-4 text-left text-center">
                    <!-- Profile Image with Square Frame centered -->
                    <div
                      class="profile-image-frame"
                      style="
                        border: 1px solid #ddd;
                        padding: 2px;
                        display: inline-block;
                        width: 180px;
                        height: 200px;
                      "
                    >
                      <img
                        src="/assets/img/profile.jpeg"
                        alt="Profile Image"
                        class="img-fluid"
                        style="width: 100%; height: 100%; object-fit: cover"
                      />
                    </div>
                  </div>

                  <!-- Profile Information aligned to left with icons -->
                  <div class="text-left">
                    <h6 class="mt-3">Kamrul Hasan Hridoy</h6>
                    <ul
                      class="mb-0 font_14 mt-3"
                      style="list-style-type: none; padding: 0"
                    >
                      <li class="d-flex align-items-center mb-2">
                        <i class="fa fa-calendar col_yell me-2"></i>
                        <span>28 (Years)</span>
                      </li>
                      <li class="d-flex align-items-center mb-2">
                        <i class="fa fa-arrows-alt-v col_yell me-3"></i>
                        <span>5'10" (Height)</span>
                      </li>
                      <li class="d-flex align-items-center mb-2">
                        <i class="fa fa-briefcase col_yell me-2"></i>
                        <span>Software Engineer</span>
                      </li>
                      <li class="d-flex align-items-center mb-2">
                        <i class="fa fa-map-marker col_yell me-2"></i>
                        <span>Dhaka, Bangladesh</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-md-10">
                <div class="list_1r">
                  <div class="list_1r1 mt-5 ml-2">
                    <!-- <h2>Profile</h2> -->
                    <!-- <p class="mb-0">Showing 9 results as per your search criteria</p> -->
                  </div>

                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue active"
                        id="personalviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#personalviews"
                        type="button"
                        role="tab"
                        aria-controls="personalviews"
                        aria-selected="true"
                      >
                        Personal Info
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="demographicviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#demographicviews"
                        type="button"
                        role="tab"
                        aria-controls="demographicviews"
                        aria-selected="false"
                      >
                        Demographic Info
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="educationviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#educationviews"
                        type="button"
                        role="tab"
                        aria-controls="educationviews"
                        aria-selected="false"
                      >
                        Education
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="employmentviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentviews"
                        type="button"
                        role="tab"
                        aria-controls="employmentviews"
                        aria-selected="false"
                      >
                        Employment
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="religiousviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#religiousviews"
                        type="button"
                        role="tab"
                        aria-controls="religiousviews"
                        aria-selected="false"
                      >
                        Religious
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="maritalviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#maritalviews"
                        type="button"
                        role="tab"
                        aria-controls="maritalviews"
                        aria-selected="false"
                      >
                        Marital Status
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="refereesviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#refereesviews"
                        type="button"
                        role="tab"
                        aria-controls="refereesviews"
                        aria-selected="false"
                      >
                        Referees
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link col_blue"
                        id="perferenceviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#perferenceviews"
                        type="button"
                        role="tab"
                        aria-controls="perferenceviews"
                        aria-selected="false"
                      >
                        Perference
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content mt-3" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="personalviews"
                      role="tabpanel"
                      aria-labelledby="personalviews-tab"
                    >
                      <div class="mt-4 p-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h5>Personal Information</h5>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row mb-3">
                          <div class="col-md-4">
                            <strong>Name:</strong>
                            <span id="displayName"> Kamrul Hasan Hridoy</span>
                          </div>
                          <div class="col-md-4">
                            <strong>Father's Name:</strong>
                            <span id="displayFatherName"> Mr. Doe</span>
                          </div>
                          <div class="col-md-4">
                            <strong>Mother's Name:</strong>
                            <span id="displayMotherName"> Mrs. Doe</span>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-md-4">
                            <strong>Age:</strong>
                            <span id="displayAge"> 30</span>
                          </div>
                          <div class="col-md-4">
                            <strong>Gender:</strong>
                            <span id="displayGender"> Male</span>
                          </div>
                          <div class="col-md-4">
                            <strong>Contact No:</strong>
                            <span id="displayContactNo"> +880 1234567890</span>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-md-4">
                            <strong>Address:</strong>
                            <span id="displayAddress"> 123 Main St, Dhaka</span>
                          </div>
                          <div class="col-md-4">
                            <strong>Upazila:</strong>
                            <span id="displayUpazila"> Dhaka Sadar</span>
                          </div>
                          <div class="col-md-4">
                            <strong>District:</strong>
                            <span id="displayDistrict"> Dhaka</span>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-md-4">
                            <strong>Email:</strong>
                            <span id="displayEmail"> johndoe@example.com</span>
                          </div>
                          <div class="col-md-4">
                            <strong>NID Number:</strong>
                            <span id="displayNid"> 1234567890123</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="demographicviews"
                      role="tabpanel"
                      aria-labelledby="demographicviews-tab"
                    >
                      <div class="mt-4 p-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h4>Demographic Information</h4>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row g-3 small-input-form mt-4">
                          <!-- Height Field -->
                          <div class="col-md-4">
                            <strong>Height (cm):</strong>
                            <span id="displayHeight"> 180</span>
                            <!-- Example value -->
                          </div>

                          <!-- Skin Color Field -->
                          <div class="col-md-4">
                            <strong>Skin Color:</strong>
                            <span id="displaySkinColor"> Fair</span>
                            <!-- Example value -->
                          </div>

                          <!-- Hair Color Field -->
                          <div class="col-md-4">
                            <strong>Hair Color:</strong>
                            <span id="displayHairColor"> Black</span>
                            <!-- Example value -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="educationviews"
                      role="tabpanel"
                      aria-labelledby="educationviews-tab"
                    >
                      <div class="mt-4 p-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h4>Education Information</h4>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row g-3 small-input-form mt-4">
                          <!-- Highest Education Field -->
                          <div class="col-md-4">
                            <strong>Highest Education:</strong>
                            <span id="displayHighestEducation"
                              >Bachelor's Degree</span
                            >
                            <!-- Example value -->
                          </div>

                          <!-- Institute Field -->
                          <div class="col-md-4">
                            <strong>Institute:</strong>
                            <span id="displayInstitute"> XYZ University</span>
                            <!-- Example value -->
                          </div>

                          <!-- Major Field -->
                          <div class="col-md-4">
                            <strong>Major:</strong>
                            <span id="displayMajor"> Computer Science</span>
                            <!-- Example value -->
                          </div>

                          <!-- Passing Year Field -->
                          <div class="col-md-4">
                            <strong>Passing Year:</strong>
                            <span id="displayPassingYear"> 2020</span>
                            <!-- Example value -->
                          </div>

                          <!-- Certificate Upload -->
                          <div class="col-md-4">
                            <strong>Certificate:</strong>
                            <span id="displayCertificate"> Uploaded PDF</span>
                            <!-- Example value -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="employmentviews"
                      role="tabpanel"
                      aria-labelledby="employmentviews-tab"
                    >
                      <div class="mt-4 p-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h4>Employment Information</h4>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row g-3 small-input-form mt-4">
                          <!-- Employment Status Field -->
                          <div class="col-md-4">
                            <strong>Employment Status:</strong>
                            <span id="displayEmploymentStatus"> Yes</span>
                            <!-- Example value -->
                          </div>

                          <!-- Employment Type Field -->
                          <div class="col-md-4">
                            <strong>Employment Type:</strong>
                            <span id="displayEmploymentType"> Private</span>
                            <!-- Example value -->
                          </div>

                          <!-- Job Type Field -->
                          <div class="col-md-4">
                            <strong>Job Type:</strong>
                            <span id="displayJobType"> Permanent</span>
                            <!-- Example value -->
                          </div>

                          <!-- Designation Field -->
                          <div class="col-md-4">
                            <strong>Designation:</strong>
                            <span id="displayDesignation">
                              Software Engineer</span
                            >
                            <!-- Example value -->
                          </div>

                          <!-- Organization Field -->
                          <div class="col-md-4">
                            <strong>Organization:</strong>
                            <span id="displayOrganization">
                              Tech Solutions Inc.</span
                            >
                            <!-- Example value -->
                          </div>

                          <!-- Organization Type Field -->
                          <div class="col-md-4">
                            <strong>Organization Type:</strong>
                            <span id="displayOrganizationType">
                              Private Sector</span
                            >
                            <!-- Example value -->
                          </div>

                          <!-- Job Experience Field -->
                          <div class="col-md-4">
                            <strong>Job Experience:</strong>
                            <span id="displayJobExperience"> 3 Years</span>
                            <!-- Example value -->
                          </div>

                          <!-- Salary Range Field -->
                          <div class="col-md-4">
                            <strong>Salary Range:</strong>
                            <span id="displaySalaryRange">
                              $50,000 - $60,000</span
                            >
                            <!-- Example value -->
                          </div>

                          <!-- Office ID Field -->
                          <div class="col-md-4">
                            <strong>Office ID:</strong>
                            <span id="displayOfficeID"> 123456</span>
                            <!-- Example value -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="religiousviews"
                      role="tabpanel"
                      aria-labelledby="religiousviews-tab"
                    >
                      <div class="mt-4 p-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h4>Religious Information</h4>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row g-3 small-input-form mt-4">
                          <!-- Religion Field -->
                          <div class="col-md-4">
                            <strong>Religion:</strong>
                            <span id="displayReligion">Islam</span>
                            <!-- Example value -->
                          </div>

                          <!-- Sect Field -->
                          <div class="col-md-4">
                            <strong>Sect:</strong>
                            <span id="displaySect">Sunni</span>
                            <!-- Example value -->
                          </div>

                          <!-- Say 5 Time Prayer Field -->
                          <div class="col-md-4">
                            <strong>Say 5 Time Prayer:</strong>
                            <span id="displaySay5TimePrayer">Yes</span>
                            <!-- Example value -->
                          </div>

                          <!-- Wear Burqa Field -->
                          <div class="col-md-4">
                            <strong>Wear Burqa:</strong>
                            <span id="displayWearBurqa">No</span>
                            <!-- Example value -->
                          </div>

                          <!-- Can Recite Quran Field -->
                          <div class="col-md-4">
                            <strong>Can Recite Quran:</strong>
                            <span id="displayCanReciteQuran">Yes</span>
                            <!-- Example value -->
                          </div>

                          <!-- Read Quran Field -->
                          <div class="col-md-4">
                            <strong>Read Quran:</strong>
                            <span id="displayReadQuran">Everyday</span>
                            <!-- Example value -->
                          </div>

                          <!-- Follows Sharia Rule Field -->
                          <div class="col-md-4">
                            <strong>Follows Sharia Rule:</strong>
                            <span id="displayFollowsSharia">Yes</span>
                            <!-- Example value -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="maritalviews"
                      role="tabpanel"
                      aria-labelledby="maritalviews-tab"
                    >
                      <div class="mt-4 p-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h4>Marital Status</h4>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row g-3 small-input-form mt-4">
                          <!-- Status Field -->
                          <div class="col-md-4">
                            <strong>Status:</strong>
                            <span id="displayStatus">Married</span>
                            <!-- Example value -->
                          </div>

                          <!-- Has Children Field -->
                          <div class="col-md-4">
                            <strong>Has Children:</strong>
                            <span id="displayHasChildren">Yes</span>
                            <!-- Example value -->
                          </div>

                          <!-- Number of Children Field -->
                          <div class="col-md-4">
                            <strong>If Yes, How many:</strong>
                            <span id="displayChildrenNumber">2</span>
                            <!-- Example value -->
                          </div>

                          <!-- Age of Children Field -->
                          <div class="col-md-4">
                            <strong>Age of Children:</strong>
                            <span id="displayChildrenAge">5, 8</span>
                            <!-- Example value -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="refereesviews"
                      role="tabpanel"
                      aria-labelledby="refereesviews-tab"
                    >
                      <div class="mt-4 p-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h4>Referees</h4>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row g-3 small-input-form mt-4">
                          <!-- Referee 1 -->
                          <div class="col-md-4">
                            <strong>Name:</strong>
                            <span id="referee1NameDisplay">John Doe</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Designation:</strong>
                            <span id="referee1DesignationDisplay">Manager</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Organization:</strong>
                            <span id="referee1OrganizationDisplay"
                              >XYZ Company</span
                            >
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Mobile Number:</strong>
                            <span id="referee1MobileDisplay">+1234567890</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Email:</strong>
                            <span id="referee1EmailDisplay"
                              >john.doe@example.com</span
                            >
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Relation with Candidate:</strong>
                            <span id="referee1RelationDisplay"
                              >Former Supervisor</span
                            >
                            <!-- Example value -->
                          </div>

                          <!-- Referee 2 -->
                          <div class="col-md-4 mt-3">
                            <strong>Name:</strong>
                            <span id="referee2NameDisplay">Jane Smith</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Designation:</strong>
                            <span id="referee2DesignationDisplay"
                              >Team Lead</span
                            >
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Organization:</strong>
                            <span id="referee2OrganizationDisplay"
                              >ABC Corp</span
                            >
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Mobile Number:</strong>
                            <span id="referee2MobileDisplay">+0987654321</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Email:</strong>
                            <span id="referee2EmailDisplay"
                              >jane.smith@example.com</span
                            >
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Relation with Candidate:</strong>
                            <span id="referee2RelationDisplay">Mentor</span>
                            <!-- Example value -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="perferenceviews"
                      role="tabpanel"
                      aria-labelledby="perferenceviews-tab"
                    >
                      <div class="mt-4 m-2">
                        <div
                          class="d-flex justify-content-between align-items-center mb-3"
                        >
                          <h4>Perference</h4>
                          <a href="#" class="btn btn-link" title="Edit">
                            <i class="fa fa-edit" style="font-size: 20px"></i>
                          </a>
                        </div>
                        <hr />

                        <div class="row g-3 small-input-form mt-4">
                          <!-- Referee 1 -->
                          <div class="col-md-4">
                            <strong>Age Range:</strong>
                            <span id="ageRangeDisplay">20-25</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Education Level:</strong>
                            <span id="educationLevelDisplay">Bachelor</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Employment Type:</strong>
                            <span id="employmentTypeDisplay">Private</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Salary Range:</strong>
                            <span id="salaryRangeDisplay">20000-30000</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Marital Status:</strong>
                            <span id="maritalStatusDisplay">Married</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4">
                            <strong>Home District:</strong>
                            <span id="homeDistrictDisplay">Dhaka</span>
                            <!-- Example value -->
                          </div>

                          <!-- Referee 2 -->
                          <div class="col-md-4 mt-3">
                            <strong>Read Quran:</strong>
                            <span id="ReadQuranDisplay">Everyday</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Lives in:</strong>
                            <span id="LivesDisplay">Bangladesh</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Religion:</strong>
                            <span id="ReligionDisplay">Islam</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Sect:</strong>
                            <span id="SectDisplay">Sunni</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Say 5 Time Prayer:</strong>
                            <span id="PrayerDisplay">Yes</span>
                            <!-- Example value -->
                          </div>
                          <div class="col-md-4 mt-3">
                            <strong>Wear Burqa:</strong>
                            <span id="BurqaDisplay">Yes</span>
                            <!-- Example value -->
                          </div>

                          <div class="col-md-4 mt-3">
                            <strong>Can Recite Quran:</strong>
                            <span id="ReciteQuranDisplay">Yes</span>
                            <!-- Example value -->
                          </div>

                          <div class="col-md-4 mt-3">
                            <strong>Follows Sharia Rule:</strong>
                            <span id="ShariaDisplay">Yes</span>
                            <!-- Example value -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "@/other/Validator";
import axios from "axios";

export default {
  data() {
    return {
      profiles: [],
      customers: [], // Array to hold customer data
      errors: null, // To handle any errors
      errorsmessage: "",
    };
  },

  methods: {
    perferenceCustomerList() {
      axios
        .get("customer/profile/list")
        .then((response) => {
          if (response.data.success) {
            this.customers = response.data.data; // Set the customers from API response
          } else {
            this.errors = response.data.message; // Handle error message
          }
        })
        .catch((error) => {
          console.log("Error fetching customer list:", error);
          this.errors = "No matching your preference found."; // Set error message
        });
    },

    matchingList() {
      axios
        .get("customer/matching/list")
        .then((response) => {
          if (response.data.success) {
            this.profiles = response.data.data; // Set the customers from API response
          } else {
            this.errorsmessage = response.data.message; // Handle error message
          }
        })
        .catch((error) => {
          console.log("Error fetching profiles list:", error);
          this.errorsmessage = "No matching profiles found"; // Set error message
        });
    },

    // matchingList() {
    //   axios
    //     .get("/customer/matching/list")
    //     .then((response) => {
    //       if (response.data.success) {
    //         this.profiles = response.data.data;
    //       } else {
    //         this.errorMessage = 'No matching profiles found';
    //       }
    //     })
    //     .catch((error) => {
    //       this.errorMessage = 'An error occurred while fetching profiles';
    //       console.error(error);
    //     });
    // },

    addPersonalInfo() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate personal information fields
      var error = validator.validated([
        { field: "name", value: this.name, type: "required" },
        { field: "father_name", value: this.father_name, type: "required" },
        { field: "mother_name", value: this.mother_name, type: "required" },
        { field: "age", value: this.age, type: "required|integer" },
        { field: "gender", value: this.gender, type: "required" },
        { field: "district", value: this.district, type: "required" },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        var payload = {
          personal_infos: {
            name: this.name,
            father_name: this.father_name,
            mother_name: this.mother_name,
            age: this.age,
            gender: this.gender,
            line_address: this.line_address,
            upazila: this.upazila,
            district: this.district,
            contact_no: this.contact_no,
            email: this.email,
            nid_no: this.nid_no,
            guardian_name: this.guardian_name,
            relation_guardian: this.relation_guardian,
            guardian_mobile: this.guardian_mobile,
            guardian_email: this.guardian_email,
            guardian_nid: this.guardian_nid,
            lives_in: this.lives_in,
            country_name_if_abroad: this.country_name_if_abroad,
            number_of_sibling: this.number_of_sibling,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);

            alert("Personal information saved successfully!");
            this.personal_infos = {
              name: "",
              father_name: "",
              mother_name: "",
              age: null,
              gender: "",
              line_address: "",
              upazila: "",
              district: "",
              contact_no: "",
              email: "",
              nid_no: "",
              guardian_name: "",
              relation_guardian: "",
              guardian_mobile: "",
              guardian_email: "",
              guardian_nid: "",
              lives_in: "",
              country_name_if_abroad: "",
              number_of_sibling: null,
            };
            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to save personal information!");
          });
      }
    },

    addDemographicInfo() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate personal information fields
      var error = validator.validated([
        { field: "height", value: this.height, type: "required" },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        var payload = {
          demographic_infos: {
            height: this.height,
            skin_color: this.skin_color,
            hair_color: this.hair_color,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);

            alert("Demographic information saved successfully!");
            this.demographic_infos = {
              height: "",
              skin_color: "",
              hair_color: "",
            };
            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to save personal information!");
          });
      }
    },

    addEducationInfo() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate personal information fields
      var error = validator.validated([
        {
          field: "education_level",
          value: this.education_level,
          type: "required",
        },
        { field: "institute", value: this.institute, type: "required" },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        var payload = {
          educational_infos: {
            education_level: this.education_level,
            institute: this.institute,
            major: this.major,
            passing_year: this.passing_year,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);

            alert("Personal information saved successfully!");
            this.educational_infos = {
              education_level: "",
              institute: "",
              major: "",
              passing_year: "",
            };
            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to save personal information!");
          });
      }
    },

    addEmploymentInfo() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate personal information fields
      var error = validator.validated([
        {
          field: "employment_type",
          value: this.employment_type,
          type: "required",
        },
        { field: "job_type", value: this.job_type, type: "required" },
        { field: "designation", value: this.designation, type: "required" },
        {
          field: "organization",
          value: this.organization,
          type: "required|integer",
        },
        { field: "org_type", value: this.org_type, type: "required" },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        var payload = {
          employment_infos: {
            employment_status: this.employment_status,
            employment_type: this.employment_type,
            job_type: this.job_type,
            designation: this.designation,
            organization: this.organization,
            org_type: this.org_type,
            job_experience: this.job_experience,
            salary: this.salary,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);

            alert("Personal information saved successfully!");
            this.employment_infos = {
              employment_status: "",
              employment_type: "",
              job_type: "",
              designation: "",
              organization: "",
              org_type: "",
              job_experience: "",
              salary: "",
            };
            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to save personal information!");
          });
      }
    },

    addReligiousInfo() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate personal information fields
      var error = validator.validated([
        { field: "religion", value: this.religion, type: "required" },
        { field: "sect", value: this.sect, type: "required" },
        { field: "pray_5_times", value: this.pray_5_times, type: "required" },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        var payload = {
          religious_infos: {
            religion: this.religion,
            sect: this.sect,
            pray_5_times: this.pray_5_times,
            wear_burka: this.wear_burka,
            recit_quran: this.recit_quran,
            read_quaran_daily: this.read_quaran_daily,
            follow_sharia_rule: this.follow_sharia_rule,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);

            alert("Personal information saved successfully!");
            this.religious_infos = {
              employment_status: "",
              employment_type: "",
              job_type: "",
              designation: "",
              organization: "",
              org_type: "",
              job_experience: "",
              salary: "",
            };
            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to save personal information!");
          });
      }
    },

    addMaritalInfo() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate personal information fields
      var error = validator.validated([
        {
          field: "marital_status",
          value: this.marital_status,
          type: "required",
        },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        var payload = {
          marital_infos: {
            marital_status: this.marital_status,
            has_children: this.has_children,
            no_children: this.no_children,
            age_of_first: this.age_of_first,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);

            alert("Marital information saved successfully!");
            this.marital_infos = {
              marital_status: "",
              has_children: "",
              no_children: "",
              age_of_first: "",
            };
            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to save Marital information!");
          });
      }
    },

    addRefereesInfo() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate personal information fields
      var error = validator.validated([
        { field: "name", value: this.name, type: "required" },
        {
          field: "designation",
          value: this.designation,
          type: "required|integer",
        },
        { field: "organization", value: this.organization, type: "required" },
        { field: "mobile_number", value: this.mobile_number, type: "required" },
        {
          field: "relation_with_candidate",
          value: this.relation_with_candidate,
          type: "required",
        },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        var payload = {
          referees_infos: {
            name: this.name,
            designation: this.designation,
            organization: this.organization,
            mobile_number: this.mobile_number,
            relation_with_candidate: this.relation_with_candidate,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);

            alert("Referee information saved successfully!");
            this.marital_infos = {
              marital_status: "",
              has_children: "",
              no_children: "",
              age_of_first: "",
            };
            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to save Referee information!");
          });
      }
    },

    AddPreferencesInfos() {
      // Create an instance of the Validator
      var validator = new Validator();

      // Validate profile preference fields
      var error = validator.validated([
        {
          field: "minimum_education_level",
          value: this.minimum_education_level,
          type: "required",
        },
        {
          field: "minimum_salary",
          value: this.minimum_salary,
          type: "required|integer",
        },
        {
          field: "marital_status",
          value: this.marital_status,
          type: "required",
        },
        { field: "home_district", value: this.home_district, type: "required" },
        { field: "lives_in", value: this.lives_in, type: "required" },
        { field: "religion", value: this.religion, type: "required" },
        { field: "sect", value: this.sect, type: "required" },
        {
          field: "pray_5_times",
          value: this.pray_5_times,
          type: "required|boolean",
        },
        {
          field: "wear_burka",
          value: this.wear_burka,
          type: "required|boolean",
        },
        {
          field: "recit_quran",
          value: this.recit_quran,
          type: "required|boolean",
        },
        {
          field: "read_quaran_daily",
          value: this.read_quaran_daily,
          type: "required|boolean",
        },
        {
          field: "follow_sharia_rule",
          value: this.follow_sharia_rule,
          type: "required|boolean",
        },
      ]);

      // Check for validation errors
      if (error) {
        console.log("Validation Errors:", error); // Log validation errors
        this.errors = error; // Display errors
        return;
      } else {
        // Prepare the payload
        var payload = {
          preferance_infos: {
            age_range: {
              age_min: this.age_min,
              age_max: this.age_max,
            },
            minimum_education_level: this.minimum_education_level,
            minimum_salary: this.minimum_salary,
            marital_status: this.marital_status,
            home_district: this.home_district,
            lives_in: this.lives_in,
            religion: this.religion,
            sect: this.sect,
            pray_5_times: this.pray_5_times,
            wear_burka: this.wear_burka,
            recit_quran: this.recit_quran,
            read_quaran_daily: this.read_quaran_daily,
            follow_sharia_rule: this.follow_sharia_rule,
          },
        };

        // Send the data to the API as FormData
        axios
          .put("customer/profile/self/update", payload)
          .then((response) => {
            console.log("Response from server:", response.data);
            alert("Profile preferences updated successfully!");

            // Reset the form fields
            this.age_min = "";
            this.age_max = "";
            this.minimum_education_level = "";
            this.minimum_salary = "";
            this.marital_status = "";
            this.home_district = "";
            this.lives_in = "";
            this.religion = "";
            this.sect = "";
            this.pray_5_times = "";
            this.wear_burka = "";
            this.recit_quran = "";
            this.read_quaran_daily = "";
            this.follow_sharia_rule = "";

            this.errors = {};
          })
          .catch((error) => {
            console.error("API Error:", error.response);
            this.errors = error.response
              ? error.response.data.errors || {}
              : {};
            alert("Failed to update profile preferences!");
          });
      }
    },
  },

  mounted() {
    this.perferenceCustomerList();
    this.matchingList();
  },
};
</script>

<style>
/* Ensure tab-pane background doesn't change */
.tab-pane {
  background-color: initial; /* or your preferred background color */
  border: 1px solid #dee2e6; /* or your preferred border color */
}

/* Ensure background stays when active */
.tab-pane.active {
  background-color: #f8f9fa; /* Keep it the same when active */
}

.fixed-image-size1 {
  max-height: 150px; /* Adjust the height as needed */
  object-fit: cover; /* Keeps the image centered and prevents distortion */
  width: 100%; /* Ensures the image fills the width of its container */
}
</style>
