<template>
  <div class="page-wrapper">
    <div id="content">
      <div class="container-fluid mt-4">
        <h3 class="mb-4">Hadia Payment</h3>

        <!-- User Table Card -->
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <h5>User List</h5>
            <!-- <button class="btn btn-success" id="addUserButton">
              <i class="fa fa-plus"></i> Add Item
            </button> -->
          </div>

          <div class="card-body">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Payment Type</th>
                  <th>Amount</th>
                  <th>Due Date</th>
                  <th>payment Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mr kamal uddin</td>
                  <td>+880 123 456 789</td>

                  <td>Bkash</td>
                  <td>5000</td>
                  <td>2022-01-01</td>
                  <td>2022-01-01</td>
                  <td>Paid</td>

                  <td>
                    <button class="btn btn-primary btn-sm">Invoice</button>
                    <button class="btn btn-danger btn-sm ms-2">Pay</button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Mr Zamal uddin</td>
                  <td>+880 123 456 789</td>

                  <td>Bkash</td>
                  <td>6000</td>
                  <td>2022-01-01</td>
                  <td>2022-01-01</td>
                  <td>Paid</td>

                  <td>
                    <button class="btn btn-primary btn-sm">Invoice</button>
                    <button class="btn btn-danger btn-sm ms-2">Pay</button>
                  </td>
                </tr>
                <!-- More rows can be added here -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
