<template>

    <div>

        <TopbarView/>
        <sidebar-view/>
        <router-view/>


    </div>
    
  
</template>

<script>
import TopbarView from '@/components/admin/partial/TopbarView.vue'
import SidebarView from '@/components/sidebar/AdminSidebarView.vue'
export default {
components:{
    TopbarView,
    SidebarView
},
methods:{

  ini(){
      const toggleBtn = document.querySelector(".toggle-btn");
      const sidebar = document.getElementById("sidebar");

      // Function to toggle the sidebar visibility
      toggleBtn.addEventListener("click", () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 768) {
          if (sidebar.style.left === "0px") {
            sidebar.style.left = "-250px"; // Hide the sidebar
          } else {
            sidebar.style.left = "0px"; // Show the sidebar
          }
        } else {
          sidebar.classList.toggle("hidden");
        }
      });

  },



},

mounted(){
  this.ini()

}

}
</script>

<style>

</style>