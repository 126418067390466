<template>
  <div class="page-wrapper">
    <div id="content">
      <div class="container-fluid mt-4">
        <h3 class="mb-4">Match Request</h3>

        <!-- User Table Card -->
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <h5>User List</h5>
            <!-- <button class="btn btn-success" id="addUserButton">
              <i class="fa fa-plus"></i> Add Item
            </button> -->
          </div>

          <div class="card-body">
            <table class="table table-bordered">
  <thead>
    <tr>
      <th>Name</th>
      <th>Age</th>
      <th>District</th>
      <th>Education</th>
      <th>Status</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>John Doe</td>
      <td>28</td>
      <td>Dhaka</td>
      <td>Bachelor's</td>
      <td>Varified</td>
      
      <td>
        <button class="btn btn-primary btn-sm">View</button>
        <button class="btn btn-danger btn-sm ms-2">Forward Match</button>
      </td>
    </tr>
    <tr>
      <td>Jane Smith</td>
      <td>32</td>
      <td>Chittagong</td>
      <td>Master's</td>
      <td>Unvarified</td>
      
      <td>
        <button class="btn btn-primary btn-sm">View</button>
        <button class="btn btn-danger btn-sm ms-2">Forward Match</button>
      </td>
    </tr>
    <!-- More rows can be added here -->
  </tbody>
</table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  mounted() {
  

  }
};
</script>

<style>

</style>
