<template>
  <section id="header">
    <nav
      :class="[
        'navbar',
        'navbar-expand-md',
        'navbar-light',
        'pt-2',
        'pb-2',
        'bg-white',
        { 'fixed-navbar': isFixed },
      ]"
      id="navbar_sticky"
    >
      <div class="container-xl">
        <!-- <a class="navbar-brand p-0 me-4" href="index.html">
          <img src="/assets/img/logo.svg" alt="Wedding Planner Logo" class="brand-image" style="max-height: 50px" />
        </a> -->

        <router-link
          class="nav-link navbar-brand p-0 me-4"
          aria-current="page"
          :to="{ name: 'home-view' }"
        >
          <img
            src="/assets/img/logo.svg"
            alt="Wedding Planner Logo"
            class="brand-image"
            style="max-height: 50px"
          />
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-0 ms-auto">
            <!-- <li><router-link class="dropdown-item" :to="{ name: 'company-employee' }">
            Employees
    </router-link></li> -->

            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'home-view' }"
              >
                Home
              </router-link>
            </li>

            <!-- <li class="nav-item">
            
              <a class="nav-link active" aria-current="page" href="index.html">Home</a>
            </li> -->

            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'about-view' }"
              >
                About Us
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'service-view' }"
              >
                Service
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'contact-view' }"
              >
                Contact Us
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav mb-0 ms-auto">

            <!-- <li class="nav-item">
                <a class="nav-link button" href="" @click.prevent="logout" style="color: #fff !important;"> Logout </a>
              </li> -->


            <li class="nav-item">
              <a
                class="nav-link button"
                href="javascript:void(0)"
                @click.prevent="logout"
                style="color: #fff !important"
                >Logout</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isFixed: false, // Tracking state for sticky navbar
    };
  },
  methods: {
    logout() {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        localStorage.removeItem("user");

        this.$router.push({ name: "login-view" });
      }
    },

    handleScroll() {
      // When the scroll passes the topbar, make navbar sticky
      if (window.scrollY > 100) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
  mounted() {
    // Check scroll position on mount in case the page is refreshed
    this.handleScroll();

    // Add scroll event listener
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Remove event listener to prevent memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
/* Style for the navbar */
#navbar_sticky {
  transition: all 0.3s ease;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
</style>
