import { createRouter, createWebHistory } from 'vue-router'
// User


// Auth
import LoginView from '@/components/auth/LoginView.vue'
import RegisterView from '@/components/auth/RegisterView.vue'


//anonymous
import HomeView from '@/components/anonymous/HomeView.vue'
import AboutView from '@/components/anonymous/AboutView.vue'
import ServiceView from '@/components/anonymous/ServiceView.vue'
import ContactView from '@/components/anonymous/ContactView.vue'
import FilterView from '@/components/anonymous/FilterView.vue'
import TermAndConditionView from '@/components/anonymous/TermAndConditionView.vue'
import PrivacyPolicyView from '@/components/anonymous/PrivacyPolicyView.vue'

// Admin
import AdminDashboardView from '@/components/admin/AdminDashboardView'
import ProfileView from '@/components/admin/ProfileView'
import MatchRequestView from '@/components/admin/MatchRequestView.vue'
import SuccessfulMatchView from '@/components/admin/SuccessfulMatchView.vue'
import HadiaPaymentView from '@/components/admin/HadiaPaymentView.vue'


// user
import UserDashboardView from '@/components/user/UserDashboardView'











const routes = [
  
  //User
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },


  //anonymous Route
  
  {
    path: '/',
    name: 'home-view',
    meta: { layout: "anonymous"},
    component: HomeView
  },

   {
    path: '/about',
    name: 'about-view',
    meta: { layout: "anonymous"},
    component: AboutView
  },

  {
    path: '/services',
    name: 'service-view',
    meta: { layout: "anonymous"},
    component: ServiceView
  },

  {
    path: '/contact',
    name: 'contact-view',
    meta: { layout: "anonymous"},
    component: ContactView
  },


  {
    path: '/filter',
    name: 'filter-view',
    meta: { layout: "anonymous"},
    component: FilterView
  },




  {
    path: '/T&D',
    name: 'T&D-view',
    meta: { layout: "anonymous"},
    component: TermAndConditionView
  },

  {
    path: '/privacy-policy',
    name: 'privacy-policy-view',
    meta: { layout: "anonymous"},
    component: PrivacyPolicyView
  },
  



   // Auth Route
   {
    path: '/login',
    name: 'login-view',
    meta: { layout: "auth", login: true},
    component: LoginView
   },

   {
      path: '/register',
      name: 'register-view',
      meta: { layout: "auth"},
      component: RegisterView
     },

  
  

  //Admin Route


  {
    path: '/admin',
    name: 'admin',
    meta: { layout: "admin"},
    children: [
        {
           path: 'dashboard',
           name: 'admin-dashboard',
           component: AdminDashboardView,
        },
     
        {
           path: 'profile',
           name: 'profile-view',
           component: ProfileView,
        },

        {
           path: 'match-request',
           name: 'match-request-view',
           component: MatchRequestView,
        },

        {
           path: 'successful-match',
           name: 'successful-match-view',
           component: SuccessfulMatchView,
        },

        {
           path: 'hadia-payment',
           name: 'hadia-payment-view',
           component: HadiaPaymentView,
        },
     
      
      
       
    ]
  },

  // Company Route
  {
    path: '/user',
    name: 'user',
    meta: { layout: "user"},
    children: [
        {
           path: 'dashboard',
           name: 'user-dashboard-view',
           component: UserDashboardView,
        },
     
        // { path: 'employee-profile',
        //    name: 'company-employee-profile',
        //    component: EmployeeProfileView,
        // },
     
          

   
       
    ]
  },

  
  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


 
export default router
