<template>
        <div class="wrapper">
            
<div id="content">
      <div class="container-fluid mt-4">
        <h4>Dashboard</h4>
        <p>Welcome to the dashboard</p>

        <div class="plan_1 row pb-4">
          <div class="col-md-3">
            <div class="plan_1i shadow_box p-4 px-3">
              <div class="plan_1i1 row">
                <div class="col-md-3">
                  <div class="plan_1i1l">
                    <span class="fs-1 col_yell lh-1">
                      <i class="fa fa-users"></i>
                    </span>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="plan_1i1r">
                    <h5 class="fs-6">Total Profile</h5>

                    <h6 class="mb-0">
                      <a class="col_yell" href="#">120</a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="plan_1i shadow_box p-4 px-3">
              <div class="plan_1i1 row">
                <div class="col-md-3">
                  <div class="plan_1i1l">
                    <span class="fs-1 col_yell lh-1">
                      <i class="fa fa-check-circle"></i>
                    </span>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="plan_1i1r">
                    <h5 class="fs-6">Successful Matches</h5>

                    <h6 class="mb-0">
                      <a class="col_yell" href="#">100</a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="plan_1i shadow_box p-4 px-3">
              <div class="plan_1i1 row">
                <div class="col-md-3">
                  <div class="plan_1i1l">
                    <span class="fs-1 col_yell lh-1">
                      <i class="fa fa-credit-card"></i>
                    </span>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="plan_1i1r">
                    <h5 class="fs-6">Payment Due</h5>

                    <h6 class="mb-0">
                      <a class="col_yell" href="#">40000</a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="plan_1i shadow_box p-4 px-3">
              <div class="plan_1i1 row">
                <div class="col-md-3">
                  <div class="plan_1i1l">
                    <span class="fs-1 col_yell lh-1">
                      <i class="fa fa-check"></i>
                    </span>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="plan_1i1r">
                    <h5 class="fs-6">Varified Profiles</h5>

                    <h6 class="mb-0">
                      <a class="col_yell" href="#">90</a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>