<template>
  <!-- Sidebar -->
  <div id="sidebar">
    <ul>
      <li :class="{ active: isActive('admin-dashboard') }">
        <router-link
          class="nav-link"
          :to="{ name: 'admin-dashboard' }"
          exact
        >
          <span><i class="nav-icon fas fa-tachometer-alt primary_color"></i></span>
          Dashboard
        </router-link>
      </li>
      <li :class="{ active: isActive('profile-view') }">
        <router-link
          class="nav-link"
          :to="{ name: 'profile-view' }"
          exact
        >
          <span><i class="nav-icon fa fa-users primary_color"></i></span>
          Profiles
        </router-link>
      </li>
      <li :class="{ active: isActive('match-request-view') }">
        <router-link
          class="nav-link"
          :to="{ name: 'match-request-view' }"
          exact
        >
          <span><i class="nav-icon fa fa-envelope primary_color"></i></span>
          Match Requests
        </router-link>
      </li>
      <li :class="{ active: isActive('successful-match-view') }">
        <router-link
          class="nav-link"
          :to="{ name: 'successful-match-view' }"
          exact
        >
          <span><i class="nav-icon fa fa-check-circle primary_color"></i></span>
          Successful Matches
        </router-link>
      </li>
      <li :class="{ active: isActive('hadia-payment-view') }">
        <router-link
          class="nav-link"
          :to="{ name: 'hadia-payment-view' }"
          exact
        >
          <span><i class="nav-icon fa fa-credit-card primary_color"></i></span>
          Hadia Payment
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    isActive(routeName) {
      return this.$route.name === routeName;
    }
  }
};
</script>

<style>
.active {
  /* Add your active class styles here */
  background-color: #e7f1ff; /* Example active background color */
}
</style>
