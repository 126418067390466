<template>
  <section id="login" class="p_3">
    <div class="container-fluid">
      <div class="row login_1">
        <!-- Left Image Section -->
        <div class="col-md-6 pt-5 d-none d-md-block">
          <!-- Display an image instead of the login form -->
          <img
            src="/assets/img/105.svg"
            alt="Login Image"
            class="img-fluid d-block mx-auto p-5"
          />
        </div>

        <!-- Right Register Section -->
        <div class="col-md-6">
          <form>
            <div class="login_1l text-center">
              <!-- Logo above the input fields -->
              <div class="login_1l1">
                <img
                  src="/assets/img/logo.svg"
                  alt="Logo"
                  class="mb-3"
                  width="280"
                />
                <h2 class="bg-light p-3 rounded-3 fs-10 mb-3 pt-3">Sign Up</h2>

                <input
                  @input="validator.clear('mobile')"
                  id="mobile"
                  class="form-control rounded-3"
                  v-model="mobile"
                  placeholder="Mobile Number *"
                  type="text"
                />
                
                <div class="row">
                  <div class="col-md-6">
                    <input
                      @input="validator.clear('email')"
                      id="email"
                      class="form-control rounded-3 mt-3"
                      v-model="email"
                      placeholder="Email"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6">
                    <input
                      @input="validator.clear('password')"
                      id="password"
                      class="form-control rounded-3 mt-3"
                      v-model="password"
                      placeholder="Password *"
                      type="password"
                    />
                  </div>
                </div>

                <!-- Keep me logged in & Forgot password row -->
                <div class="login_1li row mt-3">
                  <div class="col-6 text-start"></div>
                  <div class="col-6 text-end">
                    <h6 class="mb-0">
                      <a class="col_yell" href="#">Forgot Password?</a>
                    </h6>
                  </div>
                </div>
              </div>

              <!-- Register button -->
              <h6 class="mb-0 mt-2 center_sm">
                <button class="button" @click.prevent="register" style="border: none;">Register</button>
              </h6>

              <!-- Already Registered? section -->
              <h6 class="mt-3">
                Already registered?
                <router-link class="col_yell" aria-current="page" :to="{ name: 'login-view' }">
                  Login
                </router-link>
              </h6>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Validator } from "@/other/Validator";
import Notify from "@/other/Notify";
import axios from "axios";

export default {
  data() {
    return {
      mobile: "",
      email: "",
      password: "",
      validator: new Validator(),
    };
  },
  methods: {
    register() {
      var error = this.validator.validated([
        { field: "mobile", value: this.mobile, type: "required" },
        { field: "email", value: this.email, },
        {
          field: "password",
          value: this.password,
          type: "required|length",
          size: 8,
        },
      ]);

      if (error) {
        console.log(error);
      } else {
        axios
          .post("home/register", {
            mobile: this.mobile,
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            if (response && response.data) {
              console.log(response.data);
              Notify.updateNotification("User created successfully");


              this.$router.push({ name: "login-view" });
            } else {
              console.error("No response data found");
            }
          })
          .catch((error) => {
            console.error("An error occurred:", error);

            if (error.response?.data?.errors.password) {
              document.getElementById("password").classList.add("error-input");
              this.password = "";
              document.getElementById("password").placeholder =
                error.response.data.errors.password[0];
            }

            if (error.response?.data?.errors.email) {
              document.getElementById("email").classList.add("error-input");
              this.email = "";
              document.getElementById("email").placeholder =
                error.response.data.errors.email[0];
            }

            if (error.response?.data?.errors.name) {
              document.getElementById("first").style.display = "block";
              document.getElementById("first").innerHTML =
                error.response.data.errors.name[0];
            }
          });
      }
    },
  },
};
</script>

<style>
/* Style adjustments if necessary */
#login .login_1l img {
  max-width: 100%;
  height: auto;
}

.login_1l1 {
  margin: 60px;
}

a.col_yell {
  color: #f26e26; /* Customize this color if necessary */
}


.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f26e26; /* Customize color */
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Rounded corners */
}
.button:hover {
  background-color: #e25c1f; /* Darker shade on hover */
}
</style>
