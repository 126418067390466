<template>
  <section id="header">
    <nav :class="['navbar', 'navbar-expand-md', 'navbar-light', 'pt-2', 'pb-2', 'bg-white', { 'fixed-navbar': isFixed }]" id="navbar_sticky">
      <div class="container-xl">
        <router-link class="navbar-brand p-0 me-4" aria-current="page" :to="{ name: 'home-view' }">
          <img src="/assets/img/logo.svg" alt="Wedding Planner Logo" class="brand-image logo-image" />
        </router-link>

        <button class="navbar-toggler" type="button" @click="toggleMenu">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div :class="['collapse', 'navbar-collapse', { 'show': isMenuOpen }]" id="navbarSupportedContent">
          <ul class="navbar-nav mb-0 ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'home-view' }" @click="closeMenu">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'about-view' }" @click="closeMenu">About Us</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'service-view' }" @click="closeMenu">Service</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'contact-view' }" @click="closeMenu">Contact Us</router-link>
            </li>
          </ul>

          <ul class="navbar-nav mb-0 ms-auto">
            <li class="nav-item">
              <router-link class="nav-link button" :to="{ name: 'login-view' }" @click="closeMenu" style="color: #fff !important;">Login</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isFixed: false, // Tracking state for sticky navbar
      isMenuOpen: false, // Tracking the state of the mobile menu
    };
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 50) { // Adjusted threshold for smoother transition
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    toggleMenu() {
      // Toggle the menu visibility
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      // Ensure the menu is closed after clicking a link
      this.isMenuOpen = false;
    },
  },
  mounted() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
/* Style for the navbar */
#navbar_sticky {
  transition: all 0.3s ease;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transform: scale(1.05); /* Slightly scale up the navbar */
  background-color: rgba(255, 255, 255, 0.9); /* Optional: slightly transparent background */
  transition: transform 0.3s ease, background-color 0.3s ease; /* Add background color transition */
}

.collapse.show {
  display: block !important;
}

.logo-image {
  max-height: 70px; /* Adjust as needed */
  width: auto; /* Keep the aspect ratio */
}
</style>
