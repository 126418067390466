<template>
<auth-master-view v-if="this.$route.meta.layout == 'auth'" />
<admin-master-view v-if="this.$route.meta.layout == 'admin'" />
<user-master-view v-if="this.$route.meta.layout == 'user'" />
<master-view v-if="this.$route.meta.layout == 'anonymous'" />


</template>

<script>
import AuthMasterView from "@/components/masterview/AuthMasterView.vue";
import AdminMasterView from "@/components/masterview/AdminMasterView.vue";
import UserMasterView from "@/components/masterview/UserMasterView.vue";
import MasterView from "./components/masterview/MasterView.vue";


export default {
  components: {
    AuthMasterView,
    AdminMasterView,
    UserMasterView,
    MasterView
    

  },

  mounted() {


    var scripts = [
 
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      var allscript = document.getElementById("allscript");
      allscript.appendChild(tag);
    });

   
  }


 





}
</script>

