<template>
  <section id="center" class="center_o bg_blue11 p_3">
    <div class="container-xl">
      <div class="row center_o1">
        <div class="col-md-12">
          <h2 class="text-white">Services</h2>
          <h6 class="mb-0 mt-3 text-white">
            <a class="text-white" href="#">Home</a>
            <span class="mx-2 text-white">/</span> Services
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section id="serv_pg" class="p_3 pt-4">
    <div class="container-xl">
      <div class="row serv_h1 mb-5 mt-3">
        <div class="col-md-12 text-center">
          <div class="serv_h1l">
            <h3>We're Offering</h3>
            <p class="mb-0">Comprehensive Solutions for a Dream Wedding</p>
          </div>
        </div>
        <!-- <div class="col-md-3">
   <div class="serv_h1r text-end pt-2">
     <h6 class="mb-0"><a class="button" href="#">All Services</a></h6>
   </div>
  </div> -->
      </div>

      <div class="row serv_pg3">
        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-clipboard"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Initial Consultation</h5>
                  <p class="mb-0">
                    We begin with an in-depth consultation to understand your
                    vision, preferences, and budget for the wedding. This
                    session allows us to get a clear idea of your needs so that
                    we can tailor the planning process specifically for you.
                  </p>
                  <p class="mt-3">
                    A comprehensive questionnaire covering themes, styles, venue
                    preferences, guest lists, and any other special
                    requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-calculator"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Budget Planning and Management</h5>
                  <p class="mb-0">
                    We help create and manage your wedding budget, ensuring that
                    your funds are allocated efficiently while sticking to your
                    financial goals.
                  </p>
                  <p class="mt-3">
                    A detailed budget breakdown, including estimated costs for
                    venues, catering, decor, entertainment, photography, and
                    other services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row serv_pg3 mt-5">
        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-building"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Venue Selection and Negotiation</h5>
                  <p class="mb-0">
                    We research, recommend, and book the perfect venue for your
                    wedding based on your style, location preference, and
                    budget. Our team also handles negotiations with the venue to
                    secure the best possible rates and terms.
                  </p>
                  <p class="mt-3">
                    A list of vetted venues, site visits (if needed), and
                    finalized contracts with terms optimized for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-link"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Vendor Sourcing and Coordination</h5>
                  <p class="mb-0">
                    We assist in finding the best vendors for your wedding,
                    including caterers, florists, photographers, musicians, and
                    more. We’ll also handle all coordination, ensuring seamless
                    collaboration between vendors.
                  </p>
                  <p class="mt-3">
                    Recommendations and contracts with vendors, including
                    scheduled meetings and ongoing communication with each.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row serv_pg3 mt-5">
        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-scissors"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Wedding Design and Styling</h5>
                  <p class="mb-0">
                    Our team helps with the overall aesthetic and design of your
                    wedding, ensuring that the theme, color scheme, and decor
                    align perfectly with your vision.
                  </p>
                  <p class="mt-3">
                    A personalized wedding design plan that includes mood
                    boards, decor samples, and layout plans for ceremony and
                    reception.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-users"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Invitations and Guest Management</h5>
                  <p class="mb-0">
                    We help design, print, and distribute your wedding
                    invitations. Additionally, we offer digital invitation
                    options and assist with guest management, including RSVPs
                    and seating arrangements.
                  </p>
                  <p class="mt-3">
                    Custom-designed invitations, RSVP tracking, and a seating
                    chart for the wedding day.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row serv_pg3 mt-5">
        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-calendar"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Timeline Creation and Day-of Coordination</h5>
                  <p class="mb-0">
                    A detailed timeline will be created to ensure that every
                    element of the wedding runs smoothly, from the arrival of
                    vendors to the ceremony and reception schedule. Our team
                    will also be present on the day of the wedding to coordinate
                    and manage the event.
                  </p>
                  <p class="mt-3">
                    A minute-by-minute timeline for your wedding day, ensuring
                    that all logistics are covered, and real-time coordination
                    to manage any unforeseen issues.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-heart"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Post-Wedding Support</h5>
                  <p class="mb-0">
                    After the wedding, we provide support for wrapping up vendor
                    payments, handling any post-wedding issues, and assisting
                    with thank-you notes for guests.
                  </p>
                  <p class="mt-3">
                    A post-wedding checklist, including a vendor payment tracker
                    and sample thank-you notes for guests
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row serv_pg3 mt-5">
        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-play-circle"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Wedding Rehearsal</h5>
                  <p class="mb-0">
                    We organize and manage your wedding rehearsal to make sure
                    that everyone involved understands their roles and the
                    sequence of events for the big day.
                  </p>
                  <p class="mt-3">
                    A well-coordinated rehearsal session, ensuring that the
                    bridal party and vendors are fully prepared for the
                    ceremony.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="serv_pg3l">
            <div class="serv_pg3li row">
              <div class="col-md-2">
                <div class="serv_pg3lil">
                  <span class="col_yell fs-1 lh-1"
                    ><i class="fa fa-plus-circle"></i
                  ></span>
                </div>
              </div>
              <div class="col-md-10">
                <div class="serv_pg3lir">
                  <h5>Optional Add-On Services</h5>
                  <p class="mb-0">
                    Additional services to make your wedding experience even
                    more seamless and special, including:
                  </p>
                  <p class="mt-2">
                    <span class="custom-bold">Destination Weddings:</span> Full
                    coordination of weddings abroad.
                  </p>
                  <p class="mt-2">
                    <span class="custom-bold">Honeymoon Planning:</span>
                    Assistance in booking and planning your honeymoon, from
                    destination selection to accommodations and activities.
                  </p>
                  <p class="mt-2">
                    <span class="custom-bold">Custom Bridal Packages:</span>
                    Styling, makeup, and attire consultation for the bride and
                    groom.
                  </p>
                  <p class="mt-2">
                    <span class="custom-bold">Event Rentals:</span> Assistance
                    in renting decor, furniture, or equipment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section id="serv_h" class="p_3 mb-5">
    <div class="container-xl">
      <div class="row serv_h3 mt-4 bg_blue11 pt-5 pb-5 mx-0 px-4">
        <div class="col-md-8">
          <div class="serv_h3l">
            <h1 class="text-white">
              Need Help Planning Your Wedding?
            </h1>
            <p class="text-white-50 mt-3">
              Planning your dream wedding can be overwhelming, but we’re here to make it effortless. Our experienced team provides personalized, step-by-step wedding planning assistance, ensuring every detail is perfect. Let us handle the stress while you enjoy your special day.
            </p>
            <h6 class="mt-4 mb-0">
              <a class="button_2" href="#">Contact</a>
            </h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="serv_h3r">
            <div class="grid clearfix">
              <figure class="effect-jazz mb-0">
                <a href="#"><img src="/assets/img/need.jpg" class="w-100" alt="abc" /></a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
