import axios from 'axios';

axios.defaults.baseURL = 'http://119.40.92.105:8448/api/';


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';


axios.interceptors.request.use(
  function(config) {

    
    if (config.url.includes("public") || config.url.includes("login") || config.url.includes("logout")) {
      config.headers.Authorization = '';
    } else {
              

      const user = JSON.parse(localStorage.getItem("user"));


      if (user) {
        config.headers.Authorization = `Bearer ${user.access_token}`;
      } else {
        console.error("User object is null or token is not available");
      }
    }

    return config;
  },
  function(error) {
    
    console.error(error);
    return Promise.reject(error);
  }
);
